<template>
  <div class="relative" :class="source.htmlElementReference">
    <div v-if="!isImageBlock" :style="blockStyles">
      <sup v-if="isNumberedParagraph" class="mr-1">{{ source.counter }}</sup>
      <em v-if="isLetterBlock || isDigitBlock">{{ source.counter }}.</em>
      <span class="block-text" v-html="text"></span>
    </div>
    <div v-else>
      <img :src="source.imagePath" alt="Bild" />
    </div>
  </div>
</template>

<script>
import blockMixin from '@/components/blockMixin.js';

export default {
  name: 'AdminEditorBlock',
  mixins: [blockMixin],
  props: {
    source: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="postcss" scoped>
.block-text {
  word-break: break-word;
}
</style>
