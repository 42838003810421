<template>
  <div v-if="isContextMenuOpen" :style="position" class="fixed context-menu">
    <div class="flex bg-white rounded shadow-lg">
      <button
        class="context-button"
        :class="{
          'bg-blue-400 text-white shadow-inner-backend':
            isCurrentSelectionSuperscript,
        }"
        @click="toggleSuperscript"
      >
        <svg-icon name="superscript" />
      </button>
      <button
        class="context-button"
        :class="{
          'bg-blue-400 text-white shadow-inner-backend':
            isCurrentSelectionSubscript,
        }"
        @click="toggleSubscript"
      >
        <svg-icon name="subscript" />
      </button>

      <button
        class="context-button"
        :class="{
          'bg-blue-400 text-white shadow-inner-backend': isCurrentSelectionBold,
        }"
        @click="toggleBold"
      >
        <svg-icon name="bold" />
      </button>
      <button
        class="context-button"
        :class="{
          'bg-blue-400 text-white shadow-inner-backend':
            isCurrentSelectionItalic,
        }"
        @click="toggleItalic"
      >
        <svg-icon name="italic" />
      </button>
    </div>
  </div>
</template>

<script>
import contextMenuMixin from '@/components/admin/contextMenuMixin';
import EditorEventBus from '@/helpers/EditorEventBus';
import { hasCurrentSelectionGivenHtmlElement } from '@/helpers/selection';

export default {
  mixins: [contextMenuMixin],
  data() {
    return {
      isCurrentSelectionBold: false,
      isCurrentSelectionItalic: false,
      isCurrentSelectionALink: false,
      isCurrentSelectionSuperscript: false,
      isCurrentSelectionSubscript: false,
    };
  },
  methods: {
    openContextMenuCallback() {
      this.isCurrentSelectionBold = hasCurrentSelectionGivenHtmlElement('b');
      this.isCurrentSelectionItalic = hasCurrentSelectionGivenHtmlElement('i');
      this.isCurrentSelectionSuperscript =
        hasCurrentSelectionGivenHtmlElement('sup');
      this.isCurrentSelectionALink = hasCurrentSelectionGivenHtmlElement('a');
      this.isCurrentSelectionSubscript =
        hasCurrentSelectionGivenHtmlElement('sub');
    },
    toggleBold() {
      if (!this.isCurrentSelectionBold) {
        this.makeSelectionBold();
      } else {
        this.makeSelectionNormal('b');
      }
    },
    toggleItalic() {
      if (!this.isCurrentSelectionItalic) {
        this.makeSelectionItalic();
      } else {
        this.makeSelectionNormal('i');
      }
    },
    toggleSuperscript() {
      if (!this.isCurrentSelectionSuperscript) {
        this.makeSelectionSuperscript();
      } else {
        this.makeSelectionNormal('sup');
      }
    },
    toggleSubscript() {
      if (!this.isCurrentSelectionSubscript) {
        this.makeSelectionSubscript();
      } else {
        this.makeSelectionNormal('sub');
      }
    },
    makeSelectionSuperscript() {
      this.wrapSelectionInTag('sup');
    },
    makeSelectionItalic() {
      this.wrapSelectionInTag('i');
    },
    makeSelectionBold() {
      this.wrapSelectionInTag('b');
    },
    makeSelectionSubscript() {
      this.wrapSelectionInTag('sub');
    },
    wrapSelectionInTag(htmlTag) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      let parent = range.commonAncestorContainer;

      while (parent.nodeName !== 'P') {
        parent = parent.parentNode;
      }

      const childrens = [...parent.childNodes];

      for (let i = 0; i < childrens.length; i++) {
        const child = childrens[i];

        // Check if the current child is selected
        if (!selection.containsNode(child, true)) {
          continue;
        }

        const checkElement = (innerChild) => {
          if (innerChild.nodeName === '#text') {
            const element = document.createElement(htmlTag);
            let offsetStart = 0;
            let offsetEnd = innerChild.textContent.length;

            let offsetChanged = false;

            if (innerChild === range.startContainer) {
              offsetStart = range.startOffset;
              offsetChanged = true;
            }
            if (innerChild === range.endContainer) {
              offsetEnd = range.endOffset;
              offsetChanged = true;
            }

            // Add selected text to element
            element.textContent = innerChild.textContent.substring(
              offsetStart,
              offsetEnd
            );

            if (offsetChanged) {
              this.wrapTextInElement(
                offsetStart,
                offsetEnd,
                innerChild,
                element
              );
            } else {
              innerChild.parentNode.insertBefore(element, innerChild);
              innerChild.parentNode.removeChild(innerChild);
            }
          } else {
            innerChild.childNodes.forEach((newChild) => {
              checkElement(newChild);
            });
          }
        };

        checkElement(child);
      }

      EditorEventBus.$emit(
        'updateBlockText',
        this.currentBlock,
        parent.innerHTML
      );
    },
    wrapTextInElement(offsetStart, offsetEnd, child, element) {
      if (offsetStart === 0) {
        // Add element before children (because there is no offset at the start)
        child.textContent = child.textContent.substring(offsetEnd);
        child.parentNode.insertBefore(element, child);
      } else if (offsetEnd === child.textContent.length) {
        // Add element after children (because there is no offset at the end)
        child.textContent = child.textContent.substring(0, offsetStart);
        child.after(element);
      } else {
        // Add element after children
        child.after(element);

        const textAfter = document.createTextNode(
          child.textContent.substring(offsetEnd)
        );
        child.textContent = child.textContent.substring(0, offsetStart);

        // Add new text after the element
        element.after(textAfter);
      }
    },
    makeSelectionNormal(searchTag) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);

      let parent = range.commonAncestorContainer;

      while (parent.nodeName !== 'P') {
        parent = parent.parentNode;
      }

      const elements = parent.querySelectorAll(searchTag);

      elements.forEach((element) => {
        if (selection.containsNode(element, true)) {
          const parent = element.parentNode;
          while (element.firstChild) {
            // This line inserts the text node to the parent
            parent.insertBefore(element.firstChild, element);
          }
          // Remove the old wrapped element (e.g. <b> or <i>)
          parent.removeChild(element);
          parent.normalize();
        }
      });

      EditorEventBus.$emit(
        'updateBlockText',
        this.currentBlock,
        parent.innerHTML
      );
    },
  },
};
</script>
