class Result {
  constructor(payload) {
    this.id = payload && payload.id ? payload.id : null;
    this.originOfLaw = payload && payload.originOfLaw ? payload.originOfLaw : null;
    this.creator = payload && payload.creator ? payload.creator : null;
    this.typeOfLaw = payload && payload.typeOfLaw ? payload.typeOfLaw : null;
    this.order = payload && payload.order ? payload.order : null;
    this.title = payload && payload.title ? payload.title : null;
    this.description = payload && payload.description ? payload.description : null;
    this.externalLink = payload && payload.externalLink ? payload.externalLink : null;
    this.pdf = payload && payload.pdf ? payload.pdf : null;
    this.blocks = payload && payload.blocks ? payload.blocks : null;
  }
}

export { Result };
