import { render, staticRenderFns } from "./AdminRenderViewSwitch.vue?vue&type=template&id=276b15a8&scoped=true"
import script from "./AdminRenderViewSwitch.vue?vue&type=script&lang=js"
export * from "./AdminRenderViewSwitch.vue?vue&type=script&lang=js"
import style0 from "./AdminRenderViewSwitch.vue?vue&type=style&index=0&id=276b15a8&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "276b15a8",
  null
  
)

export default component.exports