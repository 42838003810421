<template>
  <div class="mt-6 overflow-y-auto max-h-[24rem] table--scroll-container">
    <div class="link-table">
      <div class="inline-block">
        {{ $t('editor.admin.links.title') }}
      </div>
    </div>
    <div>
      <div
        v-for="(link, index) in links"
        :key="link.id"
        class="items-start mt-4 mb-2 border-b border-gray-800"
      >
        <edit-link-template
          :index="index"
          :links-length="links.length"
          @moveUp="moveUp(link)"
          @moveDown="moveDown(link)"
          @delete="deleteLink(link)"
        >
          <div class="flex flex-col flex-1">
            <span class="font-sansbold">{{ link.text }}</span>
            <span>{{ link.url }}</span>
          </div>
        </edit-link-template>
      </div>
    </div>
  </div>
</template>

<script>
import EditLinkTemplate from './EditLinkTemplate.vue';

export default {
  components: { EditLinkTemplate },
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  methods: {
    deleteLink(link) {
      this.$emit('delete', link);
    },
    moveUp(link) {
      this.$emit('moveUp', link);
    },
    moveDown(link) {
      this.$emit('moveDown', link);
    },
  },
};
</script>
