<template>
  <modal :is-open="isModalOpen" @closeModal="cancel">
    <div slot="header">
      <h3>{{ $t('blockStructure.createPictureBlock.header') }}</h3>
    </div>
    <div>
      <upload-file-zone
        v-model="image"
        class="w-full"
        accepted-file-type="image/jpeg,image/gif,image/png"
        mode="image"
      />

      <p v-if="hasHistoricalChildren" class="text-base mt-4">
        {{ $t('admin.affectsHistory') }}
      </p>
    </div>
    <div slot="footer" class="flex justify-between">
      <button type="button" class="btn" @click="cancel">
        {{ $t('blockStructure.createPictureBlock.cancel') }}
      </button>
      <button
        type="button"
        class="btn btn--primary"
        :disabled="image === null"
        @click="submit"
      >
        {{ $t('blockStructure.createPictureBlock.submit') }}
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal';
import UploadFileZone from '@/components/admin/upload/UploadFileZone.vue';

export default {
  name: 'CreatePictureBlock',
  components: {
    Modal,
    UploadFileZone,
  },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    hasHistoricalChildren: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      image: null,
    };
  },
  methods: {
    resetModalData() {
      this.image = null;
    },
    submit() {
      const payload = {
        image: this.image,
        htmlElementReference: 'image',
      };
      this.$emit('create', payload);
      this.cancel();
    },
    cancel() {
      this.resetModalData();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
