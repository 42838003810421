<template>
  <modal
    :is-open="isOpen"
    class-container="link--modal"
    @closeModal="closeModal"
  >
    <template #header>
      <div class="text-4xl font-sansbold">
        {{ $t('editor.admin.links.createLinkHeader') }}
      </div>
    </template>

    <warning
      v-if="inPlace"
      :text="$t('editor.admin.links.createInPlaceWarning')"
    />

    <tabs :tabs="tabs" class="pb-1 mt-10" :destroy-hidden-tabs="false">
      <template #0>
        <create-internal-links-current-document
          :block="block"
          :in-place="inPlace"
          :document="document"
          :block-list="blockList"
          @addLink="addLink"
          @removeLink="removeBlockLink"
        />
      </template>
      <template #1>
        <create-internal-links
          :block="block"
          :in-place="inPlace"
          @addLink="addLink"
          @removeLink="removeBlockLink"
        />
      </template>
      <template #2>
        <create-external-links
          :block="block"
          :in-place="inPlace"
          :selection="linkText"
          @addLink="addLink"
          @clear="clearLinksToAdd"
        />
      </template>
    </tabs>
    <div v-if="!inPlace">
      <h3 class="mt-6 text-base font-sansbold">
        {{ $t('editor.admin.links.internal.selectedDocuments') }}
      </h3>
      <div class="text-gray-400 max-h-[16rem] overflow-scroll">
        <template v-for="tab in tabs">
          <div :key="tab.slotName">
            <h4 class="mt-8 text-base font-sansbold">{{ tab.title }}</h4>
            <ol>
              <li
                v-for="link in getLinksFromTab(tab.slotName)"
                :key="`selected-${link.destination || link.linkedDocument}`"
              >
                {{ link.display }}
              </li>
            </ol>
          </div>
        </template>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-between">
        <button class="btn btn--secondary" @click="closeModal">
          {{ $t('general.cancel') }}
        </button>
        <button
          class="btn btn--primary"
          :disabled="linksToAdd.length === 0"
          @click="saveLinks"
        >
          {{ $t('editor.admin.links.createLinks') }}
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal';
import { url } from 'vuelidate/lib/validators';
import { searchDocumentWithNoPagination } from '@/services/document.js';
import Tabs from '@/components/Tabs.vue';
import CreateInternalLinks from '@/components/admin/link/CreateInternalLinks.vue';
import CreateExternalLinks from '@/components/admin/link/CreateExternalLinks.vue';
import CreateInternalLinksCurrentDocument from '@/components/admin/link/CreateInternalLinksCurrentDocument.vue';
import Warning from '@/components/admin/Warning.vue';

export default {
  components: {
    Modal,
    Tabs,
    CreateInternalLinks,
    CreateExternalLinks,
    CreateInternalLinksCurrentDocument,
    Warning,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    inPlace: {
      type: Boolean,
      default: true,
    },
    currentLink: {
      type: Object,
      default: null,
    },
    block: {
      type: Number,
      default: null,
    },
    document: {
      type: Object,
      required: true,
    },
    blockList: {
      type: Object,
      required: true,
    },
  },
  validations: {
    linkURL: {
      url,
    },
  },
  data() {
    return {
      results: [],
      linkText: '',
      offset: { start: 0, end: 0 },
      linkURL: '',
      selectedBlock: null,
      linksToAdd: [],
    };
  },
  computed: {
    tabs() {
      const currentDocument = {
        title: this.$t('editor.admin.links.currentDocument'),
        slotName: '0',
      };
      const internal = {
        title: this.$t('editor.admin.links.internalLinks'),
        slotName: '1',
      };
      const external = {
        title: this.$t('editor.admin.links.externalLinks'),
        slotName: '2',
      };
      if (this.inPlace) {
        return [currentDocument, internal, external];
      }
      return [internal, external];
    },
    blocks() {
      return this.blockList.getBlocks();
    },
  },
  watch: {
    isOpen(newValue) {
      if (!newValue) {
        return;
      }

      if (window.getSelection().type !== 'Range') {
        return;
      }
      const block = this.blockList.findBlock(this.block);
      const selection = window.getSelection().getRangeAt(0);

      const commonAncestorContainer = selection.commonAncestorContainer;
      const parentElement = this.getParentElement(commonAncestorContainer);

      const offsets = this.calculateOffset(
        selection.startContainer,
        selection.endContainer,
        parentElement
      );

      const offsetInBlock = {
        start: offsets[0] + selection.startOffset,
        end: offsets[0] + offsets[1] + selection.endOffset,
      };

      this.offset = offsetInBlock;

      this.linkText = block.text.substring(
        offsetInBlock.start,
        offsetInBlock.end
      );
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
      this.resetState();
    },
    async searchResults(searchTerm) {
      let response = await searchDocumentWithNoPagination(searchTerm);
      this.results = response.results;
    },
    resetState() {
      this.linkText = '';
      this.results = [];
      this.linkURL = '';
      this.selectedBlock = null;
      this.linksToAdd = [];
    },
    getParentElement(commonAncestorContainer) {
      let parentElement = commonAncestorContainer;

      // If the current block is a table search for the table element
      while (
        parentElement.nodeName !== 'TABLE' &&
        parentElement.nodeName !== 'P'
      ) {
        parentElement = parentElement.parentElement;
      }

      return parentElement;
    },
    saveLinks() {
      this.$emit('saveLinks', this.linksToAdd, this.offset);
      this.resetState();
    },
    selectBlock(block) {
      this.selectedBlock = block;
    },
    calculateOffset(selectionStart, selectionEnd, parentElement) {
      let end = false;
      let startOffset = 0;
      let endOffset = 0;

      let passedStart = false;

      let isTable = parentElement.nodeName === 'TABLE';

      const walkThroughChildren = (element) => {
        let offset = 0;

        // End the recursion if we passed the start and the element is not in the selection (which means we also passed the end)
        // or end if the current element is the selection end element
        if (
          (!window.getSelection().containsNode(element, true) && passedStart) ||
          (!window.getSelection().containsNode(element) &&
            selectionStart === selectionEnd &&
            passedStart) ||
          element === selectionEnd ||
          end
        ) {
          return true;
        }

        // We passed the start if the element is the selectionStart
        if (element === selectionStart) {
          passedStart = true;
        }

        // If the node is a text just add the text length to the offset
        if (element.nodeName === '#text') {
          offset += element.textContent.length;
        } else if (element.nodeName === 'A') {
          // If the node is a link we add the [link=ID][/link] to the offset
          const linkId = element.getAttribute('data-link-id');
          offset += '[link=]'.length + '[/link]'.length + linkId.length;
        } else if (element.nodeName === 'IMG') {
          offset += element.outerHTML.length;
        } else if (isTable && !element.getAttribute('contenteditable')) {
          // We add the html tag to the offset (2 for < and >)
          offset += element.nodeName.length + 2;
        } else if (
          element.nodeName !== 'P' &&
          !element.getAttribute('contenteditable')
        ) {
          offset += element.nodeName.length + 2;
        }

        if (passedStart) {
          endOffset += offset;
        } else {
          startOffset += offset;
        }

        // We passed the start of the element if the current element contains the selection start element
        if (
          Array.from(element?.childNodes).includes(selectionStart) &&
          element !== parentElement &&
          !element.getAttribute('contenteditable')
        ) {
          passedStart = true;
        }

        if (element.childNodes) {
          const childNodes = element.childNodes;
          for (let i = 0; i < childNodes.length; i++) {
            const child = childNodes[i];
            if (walkThroughChildren(child)) {
              end = true;
              return;
            }
          }
        }

        // if the parent is a table we want to also add the closing tag to the offset
        if (
          isTable &&
          element.nodeName !== '#text' &&
          element.nodeName !== 'A' &&
          !element.getAttribute('contenteditable') &&
          element.nodeName !== 'BR' &&
          element.nodeName !== 'IMG' &&
          !end
        ) {
          // Add tag name and </> to offset
          if (passedStart) {
            endOffset += element.nodeName.length + 3;
          } else {
            startOffset += element.nodeName.length + 3;
          }
        } else if (
          !isTable &&
          element.nodeName !== '#text' &&
          element.nodeName !== 'A' &&
          element.nodeName !== 'P' &&
          element.nodeName !== 'BR' &&
          element.nodeName !== 'IMG'
        ) {
          // Add the html tag if the element is not one of the elements that should be ignored (text, a, div, p)
          if (passedStart) {
            endOffset += element.nodeName.length + 3;
          } else {
            startOffset += element.nodeName.length + 3;
          }
        }
      };

      walkThroughChildren(parentElement);

      return [startOffset, endOffset];
    },
    getCreatorFromResult(result) {
      return result.creator?.name || '';
    },
    addLink(link) {
      if (link.inPlace && this.linkText) {
        link.text = this.linkText;
      }

      // Hack to uncheck all radio buttons if we add an external inplace link
      // Otherwise we would need to inform all childrens, that they uncheck the radio buttons
      if (link.url && link.inPlace) {
        const radioButtons = document.querySelectorAll('input[name="link"]');
        radioButtons.forEach((radioButton) => {
          radioButton.checked = false;
        });
      }

      if (link.inPlace) {
        this.linksToAdd = [link];
      } else {
        this.linksToAdd.push(link);
      }
    },
    removeBlockLink(destinationId) {
      this.linksToAdd = this.linksToAdd.filter((link) => {
        if (link.destination) {
          return link.destination !== destinationId;
        }
        return link.linkedDocument !== destinationId;
      });
    },
    getLinksFromTab(tab) {
      return this.linksToAdd.filter((link) => link.tab === tab);
    },
    clearLinksToAdd() {
      this.linksToAdd = [];
    },
  },
};
</script>

<style lang="postcss" scoped>
/deep/ .tabs--buttons {
  @apply justify-start;

  & .tab-btn {
    @apply mr-16;
  }
}
</style>

<style lang="postcss">
.link--modal {
  @apply max-w-[1274px];
}
</style>
