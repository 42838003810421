<template>
  <div :style="{ 'margin-left': source.depth * 20 + 'px' }" class="pb-4">
    <div
      ref="blockWrapper"
      class="block-wrapper"
      :class="{
        'block--disabled': isRootBlock,
        'block--deleted': isBlockDeleted,
      }"
    >
      <div v-if="!isImageBlock" class="flex w-full">
        <span v-if="isListElement" class="mr-2 text-xl">
          {{ source.counter }}.
        </span>
        <span v-if="isListBlock" class="mr-2">•</span>
        <table-block
          v-if="isTableBlock"
          :block="source"
          :text="blockText"
          :document="document"
          :block-list="blockList"
          @input="updateBlock"
        />
        <p
          v-else
          :id="`block-${source.id}`"
          ref="structuringBlockText"
          v-line-clamp="lines"
          class="block-text"
          :contenteditable="!isRootBlock && !isBlockDeleted"
          :class="source.htmlElementReference"
          @input="updateBlock"
          @click="onSelect"
          v-html="blockText"
        />
      </div>
      <div v-else>
        <img :src="source.imagePath" alt="Bild" class="max-w-sm" />
      </div>
      <block-toolbar
        v-if="!isParentDeleted"
        class="ml-8"
        :disabled="isRootBlock"
        :block="source"
        :blocks-length="blocksLength"
      />
    </div>
  </div>
</template>

<script>
import BlockToolbar from './BlockToolbar.vue';
import EditorEventBus from '@/helpers/EditorEventBus';
import TableBlock from '@/components/admin/table/TableBlock';
import { replaceLinksToHTMLLinks } from '@/helpers/links';
import blockMixin from '@/components/blockMixin.js';

export default {
  name: 'AdminDocumentStructuringBlock',
  components: {
    BlockToolbar,
    TableBlock,
  },
  mixins: [blockMixin],
  props: {
    source: {
      type: Object,
      required: true,
    },
    blocksLength: {
      type: Number,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    blockList: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lines: 1,
      blockText: '',
    };
  },
  computed: {
    isRootBlock() {
      return this.source.depth === 0;
    },
    isListElement() {
      return (
        this.source.htmlElementReference === 'digit' ||
        this.source.htmlElementReference === 'letter'
      );
    },
    isBlockDeleted() {
      return this.source.isDeleted;
    },
    isParentDeleted() {
      const parent = this.blockList.findBlock(this.source.parent);
      if (!parent) {
        return false;
      }
      return parent.isDeleted;
    },
  },
  watch: {
    'source.isRepealed'() {
      this.setText();
    },
  },
  mounted() {
    this.setText();
    EditorEventBus.$on('updateBlockTable', this.updateBlockTable);
    if (!this.$refs.structuringBlockText) {
      return;
    }
    this.$refs.structuringBlockText.addEventListener('copy', this.handleCopy);
    window.addEventListener('click', this.handleWindowClick, { capture: true });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    EditorEventBus.$off('updateBlockTable', this.updateBlockTable);

    if (!this.$refs.structuringBlockText) {
      return;
    }

    this.$refs.structuringBlockText.removeEventListener(
      'copy',
      this.handleCopy
    );
    window.removeEventListener('click', this.handleWindowClick);
  },
  methods: {
    onSelect() {
      if (!this.$refs.structuringBlockText) {
        return;
      }
      const parent = this.$refs.blockWrapper;

      // Check whether the text height is more than one line if so we need to expand the block.
      if (this.$refs.structuringBlockText.scrollHeight > parent.clientHeight) {
        this.lines = 9999;
      }
    },
    updateBlock(e) {
      if (this.isBlockDeleted) {
        e.preventDefault();
        return;
      }
      EditorEventBus.$emit(
        'updateBlockText',
        this.source.id,
        e.target.innerHTML
      );
    },
    setText() {
      this.blockText = replaceLinksToHTMLLinks(
        this.source.text,
        this.source.links
      );
    },
    updateBlockTable(blockId, text) {
      if (blockId === this.source.id) {
        this.blockText = replaceLinksToHTMLLinks(text, this.source.links);
        EditorEventBus.$emit('updateBlockText', this.source.id, this.blockText);
      }
    },
    handleCopy(e) {
      // This code block is needed to remove the style copy that happens if you copy text from a contenteditable element
      // It also copies things like font-weight or font-size
      const text = document.getSelection().toString();
      const clipdata = e.clipboardData || window.clipboardData;
      clipdata.setData('text/plain', text);
      clipdata.setData('text/html', text);
      e.preventDefault();
    },
    handleWindowClick() {
      let isOutsideClick = true;

      const possibleElements = document.querySelectorAll(
        '.popover, .modal, .dg-open, .context-menu'
      );
      possibleElements.forEach((element) => {
        if (element.contains(event.target)) {
          isOutsideClick = false;
        }
      });

      if (
        this.$refs.blockWrapper &&
        this.$refs.blockWrapper.contains(event.target)
      ) {
        isOutsideClick = false;
      }

      if (!isOutsideClick) {
        return;
      }
      this.lines = 1;
    },
  },
};
</script>

<style lang="postcss" scoped>
/deep/ .trigger {
  @apply relative;
}

.block-wrapper {
  @apply w-full;
  @apply flex justify-between;
  @apply relative;
  @apply px-4 py-2;
  @apply border border-blue-700 rounded;

  &:hover {
    & .popover {
      display: block;
    }
  }

  &.block--disabled {
    @apply bg-gray-100 text-gray-300 pointer-events-none;
  }

  &.block--deleted {
    @apply text-gray-300 border-gray-300;
    background-image: linear-gradient(
      135deg,
      #d1d1d1 10%,
      #ffffff 10%,
      #ffffff 50%,
      #d1d1d1 50%,
      #d1d1d1 60%,
      #ffffff 60%,
      #ffffff 100%
    );
    background-size: 7.07px 7.07px;
  }
}

.block-text {
  @apply w-full;
  word-break: break-word !important; /* Needs to be important to adjust line clamp package */
}

p {
  @apply !p-0 !m-0;
}
</style>
