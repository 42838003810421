export default {
  data() {
    return {
      isContextMenuOpen: false,
      position: { x: 0, y: 0 },
      currentBlock: null,
    };
  },
  mounted() {
    document.addEventListener('selectionchange', this.onSelectionChange);
  },
  beforeDestroy() {
    document.removeEventListener('selectionchange', this.onSelectionChange);
  },
  methods: {
    openContextMenu() {
      if (this.openContextMenuCallback) {
        this.openContextMenuCallback();
      }

      const range = window.getSelection().getRangeAt(0);
      const clientRect = range.getBoundingClientRect();
      const left = clientRect.x;
      // Subtract 36px for the context menu height and 10px for a spacing
      const top = clientRect.y - 46;

      setTimeout(() => {
        // we need to defer adding the event listener, otherwise the currently
        // handled click will be adding the event listener and immediately trigger
        // it
        document.addEventListener('click', this.closeMenu);
      }, 200);
      this.position = {
        left: `${left}px`,
        top: `${top}px`,
      };
      this.isContextMenuOpen = true;
    },
    closeMenu() {
      this.isContextMenuOpen = false;
      document.removeEventListener('click', this.closeMenu);
    },
    onSelectionChange() {
      // Check if it is a collapsed selection or if the active element is not editable or if the active element is a table block
      if (
        window.getSelection().isCollapsed ||
        !document.activeElement.hasAttribute('contenteditable') ||
        document.activeElement.hasAttribute('data-row-index')
      ) {
        return;
      }
      // Timeout to only show the context menu after the user stopped his interaction
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const blockId = parseInt(
          document.activeElement.id.replace('block-', '')
        );
        this.currentBlock = blockId;
        this.$emit('setSelectedBlock', blockId);
        this.openContextMenu();
      }, 750);
    },
  },
};
