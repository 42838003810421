<template>
  <admin-layout class="backend">
    <div>
      <admin-editor />
    </div>
  </admin-layout>
</template>

<script>
import AdminEditor from '@/components/admin/AdminEditor';

export default {
  components: {
    AdminEditor,
  },
  mounted() {
    document.body.classList.add('backend');
    document.body.classList.remove('frontend');
  },
};
</script>
