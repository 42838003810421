<template>
  <div class="flex">
    <admin-pdf-view class="flex-1 mr-28" :document="document" tab="metadata" />
    <form class="flex-1">
      <!-- Title field -->
      <label-field
        :label="$t('upload.uploadForm.labels.title')"
        :label-class="[]"
        :required="true"
        is-block
      >
        <input v-model.trim="form.title" type="text" class="w-full" />
      </label-field>

      <!-- Shortcut field -->
      <label-field
        :label="$t('upload.uploadForm.labels.shortcut')"
        class="mt-4"
        :label-class="[]"
        is-block
      >
        <input v-model.trim="form.shortcut" type="text" class="w-full" />
        <validation-text v-if="$v.form.shortcut.$invalid" class="mt-1">
          {{ $t('editor.admin.metadata.shortcutError') }}
        </validation-text>
      </label-field>

      <!-- External Link field -->
      <label-field
        :label="$t('upload.uploadForm.labels.externalLink')"
        class="mt-4"
        is-block
        :label-class="[]"
      >
        <input
          v-model.trim="form.externalLink"
          :placeholder="$t('upload.uploadForm.labels.externalLinkPlaceholder')"
          type="url"
          class="w-full"
        />
        <span v-if="!$v.form.externalLink.url">
          {{ $t('upload.uploadForm.errors.externalLink') }}
        </span>
      </label-field>

      <!-- Creator field -->
      <label-field
        :label="$t('upload.uploadForm.labels.creator')"
        class="mt-4"
        :label-class="[]"
        :required="true"
        is-block
      >
        <single-select
          v-model="form.creator"
          name="creatorSingleSelect"
          :options="creators"
          option-lookup="id"
          display-lookup="name"
        />
      </label-field>

      <!-- Origin of law field -->
      <label-field
        :label="$t('upload.uploadForm.labels.originOfLaw')"
        is-block
        class="mt-4"
        :label-class="[]"
        :required="true"
      >
        <single-select
          v-model="form.originOfLaw"
          :options="originOfLawOptions"
          name="originOfLawSingleSelect"
          option-lookup="id"
          display-lookup="name"
        />
      </label-field>

      <!-- Type of law field -->
      <label-field
        :label="$t('upload.uploadForm.labels.typeOfLaw')"
        is-block
        class="mt-4"
        :label-class="[]"
        :required="true"
      >
        <single-select
          v-model="form.typeOfLaw"
          name="typeOfLawSingleSelect"
          :options="typesOfLaw"
          option-lookup="id"
          display-lookup="name"
        />
      </label-field>

      <!-- SR-Number field -->
      <label-field
        :label="$t('upload.uploadForm.labels.srNumber')"
        class="mt-4"
        is-block
        :label-class="[]"
      >
        <input
          v-model="form.srNumber"
          type="text"
          class="w-full"
          maxlength="50"
        />
      </label-field>

      <!-- Category field -->
      <label-field
        :label="$t('upload.uploadForm.labels.categories')"
        is-block
        :label-class="[]"
        class="mt-4"
      >
        <multi-select
          v-model="form.submittedCategories"
          :options="categories"
          track-by="id"
          label="name"
          :placeholder="$t('upload.uploadForm.placeholders.categories')"
          :multiple="true"
        >
          <span
            slot="caret"
            slot-scope="{ toggle }"
            @mousedown.prevent.stop="toggle"
          >
            <svg-icon name="chevron-down" class="svg-icon--lg" />
          </span>
        </multi-select>
      </label-field>

      <div class="flex justify-between mt-4">
        <label-field
          :label="$t('upload.uploadForm.labels.validFrom')"
          is-block
          :label-class="[]"
          :required="true"
        >
          <!-- Valid from date field -->
          <date-picker
            v-model="form.validFrom"
            name="ValidFromDatepicker"
            format="DD.MM.YYYY"
            class="date-picker"
            :placeholder="$t('upload.uploadForm.placeholders.date')"
          />
        </label-field>
        <label-field
          :label="$t('upload.uploadForm.labels.validTo')"
          is-block
          :label-class="[]"
        >
          <!-- Valid to date field -->
          <date-picker
            v-model="form.validTo"
            name="ValidToDatepicker"
            format="DD.MM.YYYY"
            class="date-picker"
            :placeholder="$t('upload.uploadForm.placeholders.date')"
          />
        </label-field>
      </div>

      <!-- Tags field -->
      <label-field
        :label="$t('upload.uploadForm.labels.tags')"
        is-block
        :label-class="[]"
        class="mt-4"
      >
        <VueTagsInput
          v-model="form.tag"
          :tags="form.tags"
          :placeholder="$t('upload.uploadForm.placeholders.tags')"
          class="w-full input"
          @tags-changed="setTags"
        />
      </label-field>
    </form>
  </div>
</template>

<script>
import LabelField from '@/components/LabelField';
import MultiSelect from '@/components/Select';
import SingleSelect from '@/components/SingleSelect';
import DatePicker from '@/components/Datepicker.vue';
import VueTagsInput from '@johmun/vue-tags-input';
import {
  getCategories,
  getTypesOfLaw,
  updateDocument,
  getOriginsOfLaw,
} from '@/services/document';
import { formatDate } from '@/helpers/DateFormatters';
import 'vue2-datepicker/index.css';
import AdminPdfView from '@/components/admin/AdminPdfView';
import ValidationText from '@/components/ValidationText.vue';
import dayjs from 'dayjs';
import EditorEventBus from '@/helpers/EditorEventBus';
import { required, url, maxLength } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

export default {
  name: 'UploadForm',
  components: {
    LabelField,
    MultiSelect,
    SingleSelect,
    DatePicker,
    VueTagsInput,
    AdminPdfView,
    ValidationText,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    activeLanguage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        title: '',
        shortcut: '',
        externalLink: '',
        srNumber: '',
        creator: '',
        typeOfLaw: '',
        originOfLaw: '',
        submittedCategories: [],
        validFrom: null,
        validTo: null,
        tag: '',
        tags: [],
      },
      categories: [],
      typesOfLaw: [],
      originOfLawOptions: [],
    };
  },
  computed: {
    ...mapState('Block', ['creators']),
  },
  watch: {
    document: function (val) {
      this.prefillForm(val);
    },
    form: {
      handler: function () {
        this.$emit('setValidation', this.$v.$invalid);
      },
      deep: true,
    },
  },
  async mounted() {
    this.categories = await getCategories();
    this.typesOfLaw = await getTypesOfLaw();
    this.originOfLawOptions = await getOriginsOfLaw();
    this.originOfLawOptions.forEach((option) => {
      option['label'] = this.$i18n.t(option['label']);
    });
    this.prefillForm(this.document);
    EditorEventBus.$on('saveTab', this.submit);
  },
  beforeDestroy() {
    EditorEventBus.$off('saveTab', this.submit);
  },
  methods: {
    cleanTags(tags) {
      // Create an array out of the tag text.
      let cleanedTags = tags.map((tag) => {
        return tag.text;
      });
      return JSON.stringify(cleanedTags);
    },
    parseTags(tags) {
      return tags.map((tag) => {
        return { text: tag, tiClasses: 'ti-valid' };
      });
    },
    cleanCategories(categories) {
      return categories.map((category) => {
        return category.id;
      });
    },
    setTags(newTags) {
      this.form.tags = newTags;
    },
    async submit() {
      const date_format = 'YYYY-MM-DD';
      let payload = {
        title: this.form.title,
        shortcut: this.form.shortcut,
        externalLink: this.form.externalLink,
        srNumber: this.form.srNumber,
        creator: this.form.creator,
        typeOfLaw: this.form.typeOfLaw,
        originOfLaw: this.form.originOfLaw,
        categories: this.cleanCategories(this.form.submittedCategories),
        validFrom: formatDate(this.form.validFrom, date_format),
        validTo: formatDate(this.form.validTo, date_format),
        tags: this.cleanTags(this.form.tags),
        language: this.activeLanguage,
      };
      try {
        await updateDocument(this.document.id, payload);
        this.$notify({
          type: 'success',
          text: this.$t('notifications.metadataSaved'),
        });
        EditorEventBus.$emit('reloadDocument');
      } catch (_) {
        this.$notify({
          type: 'error',
          text:
            e.response?.data?.message || this.$t('notifications.metadataError'),
        });
      }
    },
    prefillForm(document) {
      this.form.title = document.title || '';
      this.form.shortcut = document.shortcut || '';
      this.form.externalLink = document.externalLink || '';
      this.form.srNumber = document.srNumber || '';
      this.form.creator = document.creator ? document.creator.id : '';
      this.form.typeOfLaw = document.typeOfLaw ? document.typeOfLaw.id : '';
      this.form.originOfLaw = document.originOfLaw?.id || '';
      this.form.submittedCategories = document.categories || [];
      this.form.validFrom = document.validFrom
        ? dayjs(document.validFrom).$d
        : '';
      this.form.validTo = document.validTo ? dayjs(document.validTo).$d : '';
      this.form.tags = this.parseTags(document.tags) || [];
    },
  },
  validations() {
    return {
      form: {
        title: {
          required,
        },
        shortcut: {
          maxLength: maxLength(25),
        },
        externalLink: {
          url,
        },
        creator: {
          required,
        },
        originOfLaw: {
          required,
        },
        typeOfLaw: {
          required,
        },
        validFrom: {
          required,
        },
      },
    };
  },
};
</script>

<style lang="postcss">
.multiselect {
  @apply border-blue-700;
}

select {
  @apply border-blue-700;
}

.vue-tags-input {
  max-width: 100% !important;
  @apply py-[0.625rem] px-5;
  @apply border-blue-700;
}
.vue-tags-input .ti-input {
  @apply p-0;
  @apply text-base;
  border: none;

  & .ti-new-tag-input-wrapper {
    @apply text-base;
    @apply min-h-6;
    @apply p-0 m-0;
  }

  & .ti-tags .ti-tag {
    @apply my-0 ml-0;
    @apply bg-blue-800;
  }
}

.date-picker {
  width: auto !important;
}

.ti-new-tag-input {
  @apply focus:shadow-none;
}

.ti-tags .ti-tag {
  @apply bg-secondary-500;
}
</style>
