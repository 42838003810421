<template>
  <div class="relative">
    <div>
      <div v-if="tableOfContents" class="w-1/2 mb-10">
        <block-table-of-contents
          class="mr-16"
          :options="tableOfContents"
          @input="scrollToBlock"
        />
      </div>

      <div id="blocks">
        <virtual-list
          ref="virtualList"
          class="max-h-[90vh] overflow-y-auto break-words"
          data-key="id"
          :data-sources="blocks"
          :data-component="editBlockComponent"
          :estimate-size="60"
          :keeps="80"
          item-class="flex"
          :extra-props="{ showBlockLinkModal, document, blockList }"
        >
        </virtual-list>
      </div>
    </div>

    <admin-editor-link-modal
      :is-open="isLinkModalOpen"
      :in-place="isInPlaceLink"
      :block="selectedBlockId"
      :document="document"
      :block-list="blockList"
      @close="closeLinkModal"
      @addLink="addLinkToBlock"
      @deleteLink="deleteLinkInternal"
      @saveLinks="saveLinks"
    />
    <admin-document-block-context-menu
      ref="contextMenu"
      @showLinkModal="showInPlaceLinkModal"
      @delete="deleteInPlaceLink"
      @setSelectedBlock="setSelectedBlock"
    />
  </div>
</template>

<script>
import AdminDocumentEditBlock from '@/components/admin/AdminDocumentEditBlock';
import AdminDocumentBlockContextMenu from '@/components/admin/AdminDocumentBlockContextMenu';
import AdminEditorLinkModal from '@/components/admin/AdminEditorLinkModal';
import EditorEventBus from '@/helpers/EditorEventBus';
import VirtualList from 'vue-virtual-scroll-list';
import BlockTableOfContents from '@/components/BlockTableOfContents.vue';
import linkMixin from '@/components/admin/linkMixin.js';

export default {
  name: 'AdminDocumentEdit',
  components: {
    AdminDocumentBlockContextMenu,
    AdminEditorLinkModal,
    VirtualList,
    BlockTableOfContents,
  },
  mixins: [linkMixin],
  props: {
    document: {
      type: Object,
      required: true,
    },
    blockList: {
      type: Object,
      required: true,
    },
    blocks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      timeout: 0,
      editBlockComponent: AdminDocumentEditBlock,
      isInPlaceLink: false,
    };
  },
  computed: {
    links() {
      const links = [];
      for (let i = 0; i < this.blocks.length; i++) {
        links.push(...this.blocks[i].links);
      }

      return links;
    },
    tableOfContents() {
      return this.blockList.getTableOfContents();
    },
  },
  mounted() {
    EditorEventBus.$on('addBlockLink', this.showBlockLinkModal);
  },
  beforeDestroy() {
    EditorEventBus.$off('addBlockLink', this.showBlockLinkModal);
  },
  methods: {
    showInPlaceLinkModal() {
      this.isInPlaceLink = true;
      this.isLinkModalOpen = true;
    },
    showBlockLinkModal(blockId) {
      this.isInPlaceLink = false;
      this.isLinkModalOpen = true;
      this.selectedBlockId = blockId;
    },
    closeLinkModal() {
      this.isLinkModalOpen = false;
    },

    findLink(linkId) {
      const blockWithLink = this.blocks.find((block) =>
        block.links.some((link) => link.id === linkId)
      );

      if (!blockWithLink) {
        return null;
      }

      const link = blockWithLink.links.find((link) => link.id === linkId);

      if (!link) {
        return null;
      }

      return link;
    },
    changeView(mode) {
      this.showPdfView = mode === 'pdf';
    },
    scrollToBlock(block) {
      this.$refs.virtualList.scrollToIndex(block.index);
    },
    setSelectedBlock(block) {
      this.selectedBlockId = block;
    },
  },
};
</script>
