<template>
  <div class="flex">
    <slot />
    <div class="w-20">
      <button class="link--button" :disabled="!canBeMovedUp" @click="moveUp">
        <svg-icon name="shift-up" />
      </button>
      <button
        class="pl-4 link--button"
        :disabled="!canBeMovedDown"
        @click="moveDown"
      >
        <svg-icon name="shift-down" />
      </button>
      <button class="pl-4 link--button" @click="deleteLink">
        <svg-icon name="delete" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    linksLength: {
      type: Number,
      required: true,
    },
  },
  computed: {
    canBeMovedDown() {
      return this.index !== this.linksLength - 1;
    },
    canBeMovedUp() {
      return this.index !== 0;
    },
  },
  methods: {
    deleteLink() {
      this.$emit('delete');
    },
    moveUp() {
      this.$emit('moveUp');
    },
    moveDown() {
      this.$emit('moveDown');
    },
  },
};
</script>

<style lang="postcss" scoped>
.link--button {
  @apply text-blue-600 disabled:cursor-not-allowed disabled:text-gray-400 disabled:pointer-events-none;
}
</style>
