<template>
  <div>
    <toolbar
      ref="toolbar"
      :disabled="disabled"
      @hide="handleToolbarHide"
      @open="registerButtonClicks"
    >
      <!-- Delete block -->
      <button
        v-if="block.parent"
        class="toolbar-button"
        :title="$t('tooltips.structuringDelete')"
        @click="deleteBlockClick"
      >
        <svg-icon name="delete-block" />
      </button>
      <template v-if="!block.isDeleted">
        <button
          v-if="!isRepealedBlock"
          class="toolbar-button"
          :title="$t('tooltips.structuringCreate')"
          @click="toggleCreateBlockModal"
        >
          <svg-icon name="add" />
          <create-block
            v-if="isCreateBlockModalOpen"
            :is-modal-open="isCreateBlockModalOpen"
            :block="block"
            @close="toggleCreateBlockModal"
            @create="createBlock"
            @cancel="toggleCreateBlockModal"
          />
        </button>

        <button
          v-if="!isRepealedBlock"
          class="toolbar-button"
          :title="$t('tooltips.structuringPicture')"
          @click="toggleCreatePictureBlockModal"
        >
          <svg-icon name="picture" />
          <create-picture-block
            v-if="isCreatePictureBlockModalOpen"
            :is-modal-open="isCreatePictureBlockModalOpen"
            :has-historical-children="block.hasHistoricalChildren"
            @close="toggleCreatePictureBlockModal"
            @create="createBlock"
            @cancel="toggleCreatePictureBlockModal"
          />
        </button>

        <button
          v-if="!isRepealedBlock"
          class="toolbar-button"
          :title="$t('tooltips.structuringTable')"
          @click="toggleCreateTableBlockModal"
        >
          <svg-icon name="table" />
          <create-table-block
            v-if="isCreateTableBlockModalOpen"
            :is-modal-open="isCreateTableBlockModalOpen"
            :has-historical-children="block.hasHistoricalChildren"
            @close="toggleCreateTableBlockModal"
            @create="createBlock"
            @cancel="toggleCreateTableBlockModal"
          />
        </button>

        <!-- Shift block up -->
        <button
          v-if="block.parent"
          class="toolbar-button"
          :disabled="!blockCanBeMovedUp"
          :title="$t('tooltips.structuringMoveUp')"
          @click="moveBlockInTree('up')"
        >
          <svg-icon name="shift-up" />
        </button>

        <!-- Shift block down -->
        <button
          v-if="block.parent"
          class="toolbar-button"
          :disabled="!blockCanBeMovedDown"
          :title="$t('tooltips.structuringMoveDown')"
          @click="moveBlockInTree('down')"
        >
          <svg-icon name="shift-down" />
        </button>

        <!-- Shift block right -->
        <button
          v-if="block.parent"
          class="toolbar-button"
          :disabled="!blockCanBeMovedRight"
          :title="$t('tooltips.structuringMoveRight')"
          @click="moveBlockInTree('right')"
        >
          <svg-icon name="shift-right" />
        </button>

        <!-- Shift block left -->
        <button
          v-if="block.parent"
          class="toolbar-button"
          :disabled="!blockCanBeMovedLeft"
          :title="$t('tooltips.structuringMoveLeft')"
          @click="moveBlockInTree('left')"
        >
          <svg-icon name="shift-left" />
        </button>

        <!-- Repeal block -->
        <button
          v-if="!isRepealedBlock"
          class="toolbar-button"
          :title="$t('tooltips.structuringRepeal')"
          @click="toggleRepealModal"
        >
          <svg-icon name="repeal-block" />
          <repeal-block
            v-if="isRepealModalOpen"
            :block="block"
            :is-open="isRepealModalOpen"
            @cancel="toggleRepealModal"
            @submit="repealBlock"
          />
        </button>

        <!-- Change block format -->
        <button
          id="block-format"
          class="toolbar-button"
          :disabled="!blockFormatCanBeEdited"
          :title="$t('tooltips.structuringFormat')"
          @click="toggleChangeFormatModal"
        >
          <svg-icon name="pilcrow" class="svg-icon svg-icon--md" />
        </button>

        <!-- Block categories -->
        <button
          v-if="!isRepealedBlock"
          class="toolbar-button"
          :title="$t('tooltips.structuringCategory')"
          @click="toggleBlockCategoriesModal"
        >
          <svg-icon name="category" />
          <block-categories
            v-if="isBlockCategoriesModalOpen"
            :block="block"
            :is-open="isBlockCategoriesModalOpen"
            @cancel="toggleBlockCategoriesModal"
            @submit="updateBlockCategories"
          />
        </button>
      </template>
      <template v-else>
        <button
          class="toolbar-button"
          :title="$t('tooltips.restore')"
          @click="restoreBlock"
        >
          <svg-icon name="restore" />
        </button>
      </template>
      <!-- Create block -->
    </toolbar>
    <block-change-format
      v-if="isChangeFormatModalOpen"
      class="absolute w-[380px] top-[36px] right-[40px] z-[5]"
      :block="block"
      @close="toggleChangeFormatModal"
    />
  </div>
</template>

<script>
import EditorEventBus from '@/helpers/EditorEventBus';
import {
  deleteBlock,
  softDeleteBlock,
  restoreBlock,
} from '@/services/document';
import { createBlock, moveBlock } from '@/services/block';
import BlockChangeFormat from '@/components/admin/structuring/BlockChangeFormat.vue';
import CreateBlock from './CreateBlock';
import CreatePictureBlock from './CreatePictureBlock.vue';
import CreateTableBlock from './CreateTableBlock.vue';
import BlockCategories from '@/components/admin/structuring/BlockCategories.vue';
import RepealBlock from './RepealBlock';
import Toolbar from '@/components/admin/Toolbar.vue';

export default {
  name: 'BlockToolbar',
  components: {
    BlockChangeFormat,
    CreateBlock,
    BlockCategories,
    RepealBlock,
    Toolbar,
    CreatePictureBlock,
    CreateTableBlock,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    blocksLength: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCreateBlockModalOpen: false,
      isChangeFormatModalOpen: false,
      isRepealModalOpen: false,
      isBlockCategoriesModalOpen: false,
      isCreatePictureBlockModalOpen: false,
      isCreateTableBlockModalOpen: false,
    };
  },
  computed: {
    isRepealedBlock() {
      return this.block.isRepealed;
    },
    blockCanBeMovedLeft() {
      return this.block.depth !== 1;
    },
    blockCanBeMovedUp() {
      return this.block.index !== 1 && !this.block.isFirstChild;
    },
    blockCanBeMovedDown() {
      return (
        this.block.index !== this.blocksLength - 1 && !this.block.isLastChild
      );
    },
    blockCanBeMovedRight() {
      return this.block.indexOfChildrens !== 1;
    },
    isImageBlock() {
      return this.block.htmlElementReference === 'image';
    },
    isTableBlock() {
      return this.block.htmlElementReference === 'table';
    },
    blockNote() {
      let note = this.$t('admin.softDeleteBlockConfirmation.general');
      if (this.block.hasHistoricalChildren) {
        note += ' ' + this.$t('admin.affectsHistory');
      }
      return note;
    },
    blockFormatCanBeEdited() {
      return !this.isImageBlock && !this.isTableBlock;
    },
  },
  methods: {
    registerButtonClicks() {
      // Wait till the buttons are rendered
      window.requestAnimationFrame(() => {
        // Easy solution to close format modal on a click on another button
        this.$refs.toolbar.$el
          .querySelectorAll('.toolbar-popup button')
          .forEach((button) => {
            if (button.id !== 'block-format') {
              button.addEventListener('click', () => {
                this.isChangeFormatModalOpen = false;
              });
            }
          });
      });
    },
    getDeleteConfirmMessage() {
      let message = {
        type: 'text',
        title: this.$t('admin.softDeleteBlockConfirmation.textBlock.title'),
        body: {
          note: this.blockNote,
        },
      };
      let options = {
        okText: this.$t('admin.softDeleteBlockConfirmation.textBlock.yes'),
        cancelText: this.$t('admin.softDeleteBlockConfirmation.no'),
      };

      if (this.isImageBlock) {
        message.type = 'image';
        message.title = this.$t(
          'admin.softDeleteBlockConfirmation.imageBlock.title'
        );
        options.okText = this.$t(
          'admin.softDeleteBlockConfirmation.imageBlock.yes'
        );
      } else if (this.isTableBlock) {
        message.type = 'table';
        message.title = this.$t(
          'admin.softDeleteBlockConfirmation.tableBlock.title'
        );
        options.okText = this.$t(
          'admin.softDeleteBlockConfirmation.tableBlock.yes'
        );
      } else {
        message.body.content = this.block.text.slice(0, 120);
      }
      return { message, options };
    },
    deleteBlockClick() {
      if (!this.block.isDeleted) {
        const confirmMessage = this.getDeleteConfirmMessage();
        this.$dialog
          .confirm(confirmMessage.message, confirmMessage.options)
          .then(this.deleteBlockInternal);
      } else {
        let note = this.$t('admin.deleteBlockConfirmation.body');
        if (this.block.hasHistoricalChildren) {
          note += ' ' + this.$t('admin.affectsHistory');
        }

        this.$dialog
          .confirm(
            {
              type: 'text',
              title: this.$t('admin.deleteBlockConfirmation.title'),
              body: { note: note },
            },
            {
              okText: this.$t('admin.deleteBlockConfirmation.yes'),
              cancelText: this.$t('admin.deleteBlockConfirmation.no'),
            }
          )
          .then(this.deleteBlockInternal);
      }
    },
    async deleteBlockInternal() {
      try {
        if (this.block.isDeleted) {
          await deleteBlock(this.block.id);
        } else {
          await softDeleteBlock(this.block.id);
        }
        EditorEventBus.$emit('deleteBlock', this.block.id);
      } catch (e) {
        if (e.response) {
          if (e.response.status === 500) {
            this.$notify({
              type: 'error',
              text: e.response.data.message,
            });
          } else {
            this.$dialog.alert('', {
              conflictingLinks: e.response.data.data,
              view: 'DeleteAlert',
              customClass: 'delete-alert',
            });
          }
        }
      }
    },
    updateBlockCategories(categories) {
      this.toggleBlockCategoriesModal();
      EditorEventBus.$emit('updateBlockCategories', this.block.id, categories);
    },
    async createBlock(payload) {
      payload['parent'] = this.block.id;
      const newBlock = await createBlock(payload);
      if (!newBlock.children) newBlock.children = [];

      EditorEventBus.$emit('createNewBlock', newBlock);
    },
    toggleCreateBlockModal() {
      this.isCreateBlockModalOpen = !this.isCreateBlockModalOpen;
    },
    toggleChangeFormatModal() {
      this.isChangeFormatModalOpen = !this.isChangeFormatModalOpen;
    },
    toggleRepealModal() {
      this.isRepealModalOpen = !this.isRepealModalOpen;
    },
    toggleBlockCategoriesModal() {
      this.isBlockCategoriesModalOpen = !this.isBlockCategoriesModalOpen;
    },
    repealBlock(blockId) {
      this.toggleRepealModal();
      EditorEventBus.$emit('repealBlock', blockId);
    },
    async restoreBlock() {
      let note = this.$t('admin.restoreBlockConfirmation.body');
      if (this.block.hasHistoricalChildren) {
        note += ' ' + this.$t('admin.affectsHistory');
      }

      const confirmed = await this.$dialog.confirm(
        {
          type: 'text',
          title: this.$t('admin.restoreBlockConfirmation.title'),
          body: { note: note },
        },
        {
          okText: this.$t('admin.restoreBlockConfirmation.yes'),
          cancelText: this.$t('admin.restoreBlockConfirmation.no'),
        }
      );

      if (!confirmed) {
        return;
      }

      try {
        await restoreBlock(this.block.id);
        EditorEventBus.$emit('restoreBlock', this.block.id);
      } catch (e) {
        this.$notify({
          type: 'error',
          text: e.response.data,
        });
      }
    },
    async moveBlockInTree(direction) {
      if (this.block.hasHistoricalChildren) {
        const confirmed = await this.$dialog.confirm(
          {
            type: 'text',
            title: this.$t('admin.moveBlockConfirmation.title'),
            body: { note: this.$t('admin.affectsHistory') },
          },
          {
            okText: this.$t('admin.moveBlockConfirmation.yes'),
            cancelText: this.$t('admin.moveBlockConfirmation.no'),
          }
        );

        if (!confirmed) {
          return;
        }
      }

      try {
        const response = await moveBlock(this.block.id, {
          direction: direction,
        });
        this.$notify({
          type: 'success',
          text: this.$t('admin.moveBlock.success'),
        });
        EditorEventBus.$emit('moveBlock', response);
      } catch (e) {
        this.$notify({
          type: 'error',
          text: e.response.data,
        });
      }
    },
    toggleCreatePictureBlockModal() {
      this.isCreatePictureBlockModalOpen = !this.isCreatePictureBlockModalOpen;
    },
    toggleCreateTableBlockModal() {
      this.isCreateTableBlockModalOpen = !this.isCreateTableBlockModalOpen;
    },
    handleToolbarHide() {
      this.isChangeFormatModalOpen = false;
    },
  },
};
</script>

<style lang="postcss" scoped>
.toolbar-button {
  @apply flex px-2 py-2 text-blue-600;
  @apply hover:bg-blue-800 hover:text-white;
  @apply disabled:cursor-not-allowed disabled:text-gray-600 disabled:pointer-events-none;

  & svg {
    @apply w-5 h-5;
  }
}
</style>
