<template>
  <div>
    <modal
      :is-open="isModalOpen"
      class-container="table--modal"
      @closeModal="cancel"
    >
      <div slot="header">
        <h3>{{ $t('blockStructure.table.editor.header') }}</h3>
      </div>
      <table-editor-toolbar
        v-if="table"
        :table="table"
        :selected-column="selectedColumn"
        @picture="toggleIsUploadPictureModalOpen"
        @header="addHeaderColumn"
        @rowAbove="addRowAbove"
        @rowBelow="addRowBelow"
        @removeRow="removeRow"
        @colLeft="addColumnLeft"
        @colRight="addColumnRight"
        @removeCol="removeColumn"
        @link="openLinkModal"
        @unlink="deleteInPlaceLink"
      />
      <div class="overflow-y-auto">
        <table ref="table" class="border-separate table-fixed table-spacing">
          <thead v-if="table && table.header" class="sticky top-0 bg-white">
            <tr>
              <table-editor-cell
                v-for="(column, index) in table.header.columns"
                :key="column.id"
                element="th"
                :row-index="-1"
                :col-index="index"
                :text="column.text"
                :selected-column="selectedColumn"
                @cellFocus="onColumFocus"
                @updateText="updateBlockText"
              />
            </tr>
          </thead>
          <tbody v-if="table && table.body">
            <tr
              v-for="(row, rowIndex) in table.body.rows"
              :key="`row-${rowIndex}`"
            >
              <table-editor-cell
                v-for="(column, colIndex) in row.columns"
                :key="column.id"
                element="td"
                :row-index="rowIndex"
                :col-index="colIndex"
                :text="column.text"
                :selected-column="selectedColumn"
                @cellFocus="onColumFocus"
                @updateText="updateBlockText"
              />
            </tr>
          </tbody>
        </table>
      </div>
    </modal>
    <create-picture-block
      :is-modal-open="isUploadPictureModalOpen"
      @close="toggleIsUploadPictureModalOpen"
      @create="createImage"
      @cancel="toggleIsUploadPictureModalOpen"
    />
    <admin-editor-link-modal
      :is-open="isLinkModalOpen"
      :in-place="true"
      :block="block.id"
      :document="document"
      :block-list="blockList"
      @close="closeLinkModal"
      @saveLinks="saveLinks"
      @deleteLink="deleteLinkInternal"
    />
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import EditorEventBus from '@/helpers/EditorEventBus';
import CreatePictureBlock from '@/components/admin/structuring/CreatePictureBlock.vue';
import { uploadImage, removeImage } from '@/services/block';
import {
  parseHtmlToTableObject,
  tableEntryId,
  increaseTableEntryId,
  parseTableObjectToHtml,
} from '@/helpers/table';
import TableEditorCell from '@/components/admin/table/TableEditorCell.vue';
import TableEditorToolbar from '@/components/admin/table/TableEditorToolbar.vue';
import AdminEditorLinkModal from '@/components/admin/AdminEditorLinkModal.vue';
import linkMixin from '@/components/admin/linkMixin.js';

export default {
  name: 'TableEditor',
  components: {
    Modal,
    CreatePictureBlock,
    TableEditorCell,
    TableEditorToolbar,
    AdminEditorLinkModal,
  },
  mixins: [linkMixin],
  props: {
    block: {
      type: Object,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    blockList: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModalOpen: true,
      table: null,
      selectedColumn: [],
      isUploadPictureModalOpen: false,
      observer: null,
    };
  },
  computed: {
    blocks() {
      return this.blockList.getBlocks();
    },
    links() {
      const links = [];
      for (let i = 0; i < this.blocks.length; i++) {
        links.push(...this.blocks[i].links);
      }

      return links;
    },
  },
  watch: {
    text() {
      this.table = parseHtmlToTableObject(this.block.text, this.block.links);
    },
  },
  mounted() {
    this.selectedBlockId = this.block.id;
    this.table = parseHtmlToTableObject(this.block.text, this.block.links);

    this.$nextTick(() => {
      this.observer = new MutationObserver(this.handleMutation);
      this.observer.observe(this.$refs.table, {
        childList: true,
        subtree: true,
      });
    });
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    cancel() {
      this.isModalOpen = false;
      this.$emit('close');
    },
    onColumFocus(rowIndex, colIndex) {
      this.selectedColumn = [rowIndex, colIndex];
    },
    addRowAbove() {
      this.addRow(0);
      // Increase the selected row because we added one row before the selected one
      this.selectedColumn[0] = this.selectedColumn[0] + 1;
    },
    addRowBelow() {
      this.addRow(1);
    },
    addRow(indexChange) {
      const rows = this.table.body.rows;
      const columnLength = rows[0].columns.length;
      const row = { columns: [] };
      for (let i = 0; i < columnLength; i++) {
        row.columns.push({ id: tableEntryId, text: '' });
        increaseTableEntryId();
      }
      this.table.body.rows.splice(this.selectedColumn[0] + indexChange, 0, row);
      this.updateTable();
    },
    addColumnLeft() {
      this.addColumn(0);
      // Increase the selected column because we added one column before the selected one
      this.selectedColumn[1] = this.selectedColumn[1] + 1;
    },
    addColumnRight() {
      this.addColumn(1);
    },
    addColumn(indexChange) {
      const newIndex = this.selectedColumn[1] + indexChange;

      if (this.table.header) {
        this.table.header.columns.splice(newIndex, 0, {
          id: tableEntryId,
          text: '',
        });
        increaseTableEntryId();
      }

      const rows = this.table.body.rows;
      for (let i = 0; i < rows.length; i++) {
        rows[i].columns.splice(newIndex, 0, {
          id: tableEntryId,
          text: '',
        });
        increaseTableEntryId();
      }
      this.updateTable();
    },
    removeRow() {
      if (this.selectedColumn[0] === -1) {
        this.table.header = null;
      } else {
        this.table.body.rows.splice(this.selectedColumn[0], 1);
      }
      this.selectedColumn = [];
      this.updateTable();
    },
    removeColumn() {
      if (this.table.header) {
        this.table.header.columns.splice(this.selectedColumn[1], 1);
      }

      const rows = this.table.body.rows;
      for (let i = 0; i < rows.length; i++) {
        rows[i].columns.splice(this.selectedColumn[1], 1);
      }
      this.selectedColumn = [];
      this.updateTable();
    },
    addHeaderColumn() {
      const columnLength = this.table.body.rows[0].columns.length;

      const header = { columns: [] };

      for (let i = 0; i < columnLength; i++) {
        header.columns.push({ id: tableEntryId, text: '' });
        increaseTableEntryId();
      }

      this.table.header = header;

      this.updateTable();
    },
    updateTable() {
      EditorEventBus.$emit(
        'updateBlockText',
        this.block.id,
        parseTableObjectToHtml(this.table)
      );
    },
    updateBlockText(text, rowIndex, colIndex) {
      if (rowIndex === -1) {
        this.table.header.columns[colIndex].text = text;
      } else {
        this.table.body.rows[rowIndex].columns[colIndex].text = text;
      }

      this.updateTable();
    },
    toggleIsUploadPictureModalOpen() {
      this.isUploadPictureModalOpen = !this.isUploadPictureModalOpen;
    },
    async createImage(block) {
      const image = await uploadImage(this.block.id, block.image);

      let columnElement = document.querySelector(
        `[data-row-index="${this.selectedColumn[0]}"][data-col-index="${this.selectedColumn[1]}"]`
      );

      // Add image to cell text
      columnElement.innerHTML += `<img src="${
        process.env.VUE_APP_MEDIA_URL + image.image
      }" />`;

      this.updateBlockText(
        columnElement.innerHTML,
        this.selectedColumn[0],
        this.selectedColumn[1]
      );
    },
    startDeleteTimer(imageName) {
      // Start the deletion timer
      this.deleteTimer = setTimeout(() => {
        removeImage(this.block.id, imageName);
      }, 200);
    },
    handleMutation(mutationList) {
      mutationList.forEach((mutation) => {
        // Check if there is an image that got remove
        mutation.removedNodes.forEach((node) => {
          if (node.nodeName === 'IMG') {
            const source = node.currentSrc;
            const imageName = source.split('/').slice(-1).pop();
            // Start the deletion timer for this image
            this.startDeleteTimer(imageName);
          }
        });

        // Check if there was an image that got added
        mutation.addedNodes.forEach((node) => {
          if (node.nodeName === 'IMG') {
            // Sometimes the browser adds css variables to the style tag if you move an image in a table
            // Clear the style to prevent this
            node.style = {};

            // If an image got added we want to cancel the current deletion timeout. We need to do this because if you move images in tables, it first gets removed in the current cell and after that it gets added to the new cell.
            // To prevent the image deletion we have this deletion timeout
            window.clearTimeout(this.deleteTimer);
          }
        });
      });
    },
    closeLinkModal() {
      this.isLinkModalOpen = false;
    },
    openLinkModal() {
      this.isLinkModalOpen = true;
    },
  },
};
</script>

<style lang="postcss" scoped>
.table-spacing {
  border-spacing: 0;
}
</style>

<style lang="postcss">
.table--modal {
  @apply max-w-[90%];
  @apply flex flex-col;

  & .modal__body {
    @apply flex flex-col;
    @apply overflow-hidden;
  }

  & td,
  & th {
    @apply min-h-12;
    word-break: break-word;
    hyphens: auto;

    & p {
      @apply text-base leading-normal;
    }
  }
}
</style>
