<template>
  <div>
    <p v-for="(m, key) in messages" :key="key">{{ m }}</p>
  </div>
</template>

<script>
export default {
  props: {
    taskId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      messages: [],
      connection: null,
    };
  },
  created() {
    try {
      this.connection = new WebSocket(
        `${process.env.VUE_APP_WEBSOCKET_URL}task/${this.taskId}/`
      );

      this.connection.onmessage = (event) => {
        let message = event.data;
        if (message === 'Task done!') {
          this.$emit('done');
          this.connection.close();
          return;
        }
        if (message === 'Upload error!') {
          this.$notify({
            type: 'error',
            text:
              e.response?.data?.message ||
              this.$t('notifications.convertingFailed').toString(),
          });
          return;
        }
        this.messages.push(message);
      };
    } catch (_) {}
  },
};
</script>

<style></style>
