<template>
  <div class="mt-6 overflow-y-auto max-h-[24rem] table--scroll-container">
    <div class="link-table">
      <div class="inline-block w-1/2">
        {{ $t('editor.admin.links.title') }}
      </div>
      <div class="inline-block w-2/6 pl-2">
        {{ $t('editor.admin.links.creator') }}
      </div>
    </div>
    <div>
      <div
        v-for="(link, index) in links"
        :key="link.id"
        class="mt-4 mb-2 border-b border-gray-800"
      >
        <edit-link-template
          :index="index"
          :links-length="links.length"
          @moveUp="moveUp(link)"
          @moveDown="moveDown(link)"
          @delete="deleteLink(link)"
        >
          <div class="w-1/2">
            {{ getTitle(link) }}
          </div>
          <div class="w-2/6 px-2">
            {{ getCreator(link) }}
          </div>
        </edit-link-template>
      </div>
    </div>
  </div>
</template>

<script>
import EditLinkTemplate from './EditLinkTemplate.vue';

export default {
  components: { EditLinkTemplate },
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  methods: {
    deleteLink(link) {
      this.$emit('delete', link);
    },
    moveUp(link) {
      this.$emit('moveUp', link);
    },
    moveDown(link) {
      this.$emit('moveDown', link);
    },
    getTitle(link) {
      return link.destination?.title || link.linkedDocument?.title;
    },
    getCreator(link) {
      return link.destination?.creator || link.linkedDocument?.creator;
    },
  },
};
</script>

<style></style>
