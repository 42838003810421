<template>
  <div>
    <h3 class="text-base font-sansbold">
      {{ $t('editor.admin.links.internal.header') }}
    </h3>
    <div class="flex">
      <search
        :placeholder="$t('editor.admin.links.searchPlaceholder')"
        class="max-w-lg mt-2"
        @search="setSearchTerm"
      />

      <admin-document-list-pagination
        ref="pagination"
        class="flex lg:ml-auto"
        :count="count"
        @goto="changePage"
      />
    </div>
    <template v-if="searchTerm !== null">
      <search-result-tabs
        class="mt-8 language--tabs"
        :lang="currentLanguage"
        :documents="documents"
        :active-language="currentLanguage"
        @changeLanguageTab="setCurrentLanguage"
      />
      <div class="py-5 bg-gray-50 px-7">
        <div
          v-if="!hasNoResults"
          class="table--scroll-container max-h-[28rem] overflow-y-auto"
        >
          <div
            class="sticky top-0 pb-2 border-b-4 border-gray-800  font-sansbold bg-gray-50"
          >
            <div class="inline-block w-3/4">
              {{ $t('editor.admin.links.searchResultTitle') }}
            </div>
            <div class="inline-block w-1/4">
              {{ $t('editor.admin.links.searchResultCreator') }}
            </div>
          </div>
          <div>
            <create-internal-links-entry
              v-for="result in results"
              :key="result.id"
              :document="result"
              :block="block"
              :in-place="inPlace"
              class="mt-4 mb-2 border-b border-gray-800"
              @add="addLink"
              @remove="removeLink"
            />
          </div>
        </div>
        <div v-else>
          <span class="text-gray-400">{{
            $t('editor.admin.links.noResultSearch', { term: searchTerm })
          }}</span>
          <div class="mt-8 whitespace-pre-line">
            {{ $t('editor.admin.links.noResultText') }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Search from '@/components/admin/Search.vue';
import { searchResults } from '@/services/Search.service.js';
import AdminDocumentListPagination from '@/components/admin/AdminDocumentListPagination.vue';
import CreateInternalLinksEntry from './CreateInternalLinksEntry.vue';
import SearchResultTabs from '@/components/user/searchResult/SearchResultTabs.vue';

export default {
  components: {
    Search,
    AdminDocumentListPagination,
    CreateInternalLinksEntry,
    SearchResultTabs,
  },
  props: {
    block: {
      type: Number,
      required: true,
    },
    inPlace: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resultsPagination: {},
      currentPage: 1,
      searchTerm: null,
      currentLanguage: this.$i18n.locale,
      documents: {
        de: { count: 0, results: [] },
        fr: { count: 0, results: [] },
        it: { count: 0, results: [] },
        en: { count: 0, results: [] },
      },
    };
  },
  computed: {
    hasNoResults() {
      return this.documents[this.currentLanguage]?.results.length <= 0;
    },
    results() {
      return this.documents[this.currentLanguage]?.results;
    },
    count() {
      return this.documents[this.currentLanguage]?.count;
    },
  },
  methods: {
    async searchResults(activeLang) {
      let languages = ['de', 'fr', 'it', 'en'];

      if (activeLang) {
        languages = [activeLang];
      }

      const promises = [];
      // Add filter "show_all_articles" that indicates, we want all articles of a document.
      // This is a special search pattern for the internal links.
      languages.forEach((lang) => {
        promises.push(
          searchResults(
            this.searchTerm,
            false,
            {
              search_lang: lang,
              state: 'is_in_order',
              disable_direct_search: 'true',
              show_all_articles: 'true',
            },
            this.currentPage
          )
        );
      });
      const responses = await Promise.allSettled(promises);
      responses.forEach((response, index) => {
        this.documents[languages[index]] = response.value.response;
      });
      this.isSearched = true;
    },
    setCurrentLanguage(lang) {
      this.currentPage = 1;
      this.searchResults(this.currentLanguage);
      this.$refs.pagination.setCurrentPage(1);
      this.currentLanguage = lang;
    },
    changePage(page) {
      this.currentPage = page;
      this.searchResults(this.currentLanguage);
    },
    addLink(link) {
      this.$emit('addLink', link);
    },
    removeLink(link) {
      this.$emit('removeLink', link);
    },
    setSearchTerm(searchTerm) {
      this.currentPage = 1;
      this.$refs.pagination.setCurrentPage(1);
      this.searchTerm = searchTerm;
      this.searchResults();
    },
  },
};
</script>

<style lang="postcss" scoped>
/deep/ .is-active {
  @apply !bg-gray-50;
}

/deep/ .language--tabs {
  & button {
    @apply text-blue-600;
  }
}
</style>
