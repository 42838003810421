<template>
  <modal :is-open="isOpen" @closeModal="cancel">
    <div slot="header">
      <h3>{{ $t('blockStructure.blockCategories.header') }}</h3>
    </div>
    <multi-select
      v-model="selectedCategories"
      :options="categoryOptions"
      track-by="id"
      label="name"
      :multiple="true"
    />
    <button class="w-full mt-4 btn btn--primary" @click="setCategories">
      {{ $t('general.save') }}
    </button>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import MultiSelect from '@/components/Select';
import Modal from '@/components/Modal';

export default {
  name: 'BlockCategories',
  components: {
    MultiSelect,
    Modal,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCategories: [],
    };
  },
  computed: {
    ...mapState('Block', ['categories']),
    categoryOptions() {
      let options = [];
      if (!this.categories) {
        return options;
      }
      // Filter out all subcategories
      this.categories
        .filter((category) => category.isMainCategory)
        .forEach((category) => {
          const categoryGroupName = category.categoryGroup?.name + '/' || '';
          // Push maincategory to array
          options.push({
            id: category.id,
            name: `${categoryGroupName}/${category.name}`,
          });

          // Also add subcategories to the options array
          category.subcategories.forEach((subCategoryId) => {
            const subCategory = this.categories.find(
              (category) => category.id === subCategoryId
            );
            options.push({
              id: subCategory.id,
              name: `${categoryGroupName}/${category.name}/${subCategory.name}`,
            });
          });
        });
      return options;
    },
  },
  async mounted() {
    this.block.categories.forEach((category) => {
      this.selectedCategories.push(
        this.categoryOptions.find((option) => option.id === category)
      );
    });
  },
  methods: {
    setCategories() {
      const selectedCategoryIds = this.selectedCategories.map(
        (selectedCategory) => selectedCategory.id
      );
      this.$emit('submit', selectedCategoryIds);
    },
    cancel() {
      this.selectedCategories = [];
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
