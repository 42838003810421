<template>
  <modal :is-open="isModalOpen" @closeModal="cancel">
    <div slot="header">
      <h3>{{ $t('blockStructure.createTableBlock.header') }}</h3>
    </div>
    <div>
      <label-field
        :label="$i18n.t('blockStructure.createBlock.labels.tableColumns')"
        class="flex items-center justify-between"
      >
        <input
          :value="tableColumns"
          @input="onInputChange($event, 'tableColumns')"
        />
      </label-field>
      <label-field
        :label="$i18n.t('blockStructure.createBlock.labels.tableRows')"
        class="flex items-center justify-between mt-5"
      >
        <input :value="tableRows" @input="onInputChange($event, 'tableRows')" />
      </label-field>

      <p v-if="hasHistoricalChildren" class="text-base mt-4">
        {{ $t('admin.affectsHistory') }}
      </p>
    </div>
    <div slot="footer" class="flex justify-between">
      <button type="button" class="btn" @click="cancel">
        {{ $t('blockStructure.createTableBlock.cancel') }}
      </button>
      <button
        type="button"
        class="btn btn--primary"
        :disabled="isSubmitButtonDisabled"
        @click="submit"
      >
        {{ $t('blockStructure.createTableBlock.submit') }}
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal';
import LabelField from '@/components/LabelField';

export default {
  name: 'CreateTableBlock',
  components: {
    Modal,
    LabelField,
  },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    hasHistoricalChildren: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableColumns: null,
      tableRows: null,
    };
  },
  computed: {
    isSubmitButtonDisabled() {
      return !this.tableColumns || !this.tableRows;
    },
  },
  methods: {
    resetModalData() {
      this.tableColumns = 0;
      this.tableRows = 0;
    },
    submit() {
      const payload = {
        text: this.generateTable(),
        htmlElementReference: 'table',
      };
      this.$emit('create', payload);
      this.cancel();
    },
    cancel() {
      this.resetModalData();
      this.$emit('cancel');
    },
    testForNumber(value) {
      return /^[0-9]*$/.test(event.target.value);
    },
    onInputChange(event, field) {
      // Update the value if there are only numbers in the input value
      if (this.testForNumber(event.target.value)) {
        this[field] = event.target.value;
      } else {
        // Reset the value if there is another character than a number
        event.target.value = this.tableColumns;
      }
    },
    generateTable() {
      let table = '<table><thead><tr>';

      for (let i = 0; i < this.tableColumns; i++) {
        table += `<th></th>`;
      }

      table += '</tr></thead><tbody>';

      for (let i = 0; i < this.tableRows - 1; i++) {
        table += '<tr>';
        for (let j = 0; j < this.tableColumns; j++) {
          table += `<td></td>`;
        }
        table += '</tr>';
      }

      table += '</tbody></table>';
      return table;
    },
  },
};
</script>

<style scoped></style>
