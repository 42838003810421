var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isContextMenuOpen)?_c('div',{staticClass:"fixed context-menu",style:(_vm.position)},[_c('div',{staticClass:"flex bg-white rounded shadow-lg"},[_c('button',{staticClass:"context-button",class:{
        'bg-blue-400 text-white shadow-inner-backend':
          _vm.isCurrentSelectionSuperscript,
      },on:{"click":_vm.toggleSuperscript}},[_c('svg-icon',{attrs:{"name":"superscript"}})],1),_c('button',{staticClass:"context-button",class:{
        'bg-blue-400 text-white shadow-inner-backend':
          _vm.isCurrentSelectionSubscript,
      },on:{"click":_vm.toggleSubscript}},[_c('svg-icon',{attrs:{"name":"subscript"}})],1),_c('button',{staticClass:"context-button",class:{
        'bg-blue-400 text-white shadow-inner-backend': _vm.isCurrentSelectionBold,
      },on:{"click":_vm.toggleBold}},[_c('svg-icon',{attrs:{"name":"bold"}})],1),_c('button',{staticClass:"context-button",class:{
        'bg-blue-400 text-white shadow-inner-backend':
          _vm.isCurrentSelectionItalic,
      },on:{"click":_vm.toggleItalic}},[_c('svg-icon',{attrs:{"name":"italic"}})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }