<template>
  <modal :is-open="isModalOpen" @closeModal="cancel">
    <div slot="header">
      <h3>{{ $t('blockStructure.createBlock.header') }}</h3>
    </div>
    <div>
      <label-field
        :label="$i18n.t('blockStructure.createBlock.labels.format')"
        class="mb-5"
        is-block
      >
        <select
          v-model.trim="$v.format.$model"
          class="w-full"
          @blur="$v.format.$touch"
        >
          <option
            v-for="elementReference in filteredElementReferences"
            :key="elementReference[0]"
            :value="elementReference[0]"
          >
            {{ elementReference[1] }}
          </option>
        </select>
      </label-field>
      <validation-text v-if="$v.format.$error" class="mt-1">
        {{ $t('blockStructure.createBlock.validations.formatRequired') }}
      </validation-text>

      <label-field
        :label="$i18n.t('blockStructure.createBlock.labels.text')"
        is-block
      >
        <textarea
          v-model.trim="$v.text.$model"
          class="w-full"
          @blur="$v.text.$touch"
        />
      </label-field>
      <validation-text v-if="$v.text.$error" class="mt-1">
        {{ $t('blockStructure.createBlock.validations.textRequired') }}
      </validation-text>

      <p v-if="block.hasHistoricalChildren" class="text-base mt-4">
        {{ $t('admin.affectsHistory') }}
      </p>
    </div>
    <div slot="footer" class="flex justify-between">
      <button type="button" class="btn" @click="cancel">
        {{ $t('blockStructure.createBlock.cancel') }}
      </button>
      <button
        type="button"
        class="btn btn--primary"
        :disabled="$v.$invalid"
        @click="submit"
      >
        {{ $t('blockStructure.createBlock.submit') }}
      </button>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import Modal from '@/components/Modal';
import LabelField from '@/components/LabelField';
import ValidationText from '@/components/ValidationText.vue';

export default {
  name: 'CreateBlock',
  components: {
    Modal,
    LabelField,
    ValidationText,
  },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      format: null,
      text: null,
    };
  },
  validations() {
    return {
      format: {
        required,
      },
      text: {
        required,
      },
    };
  },
  computed: {
    ...mapState('Block', ['elementReferences']),
    filteredElementReferences() {
      return this.elementReferences.filter(
        (reference) => reference[0] !== 'image' && reference[0] !== 'table'
      );
    },
  },
  methods: {
    resetModalData() {
      this.text = '';
      this.format = '';
    },
    submit() {
      const payload = {
        text: this.text,
        htmlElementReference: this.format,
      };
      this.$emit('create', payload);
      this.cancel();
    },
    cancel() {
      this.resetModalData();
      this.resetValidation();
      this.$emit('cancel');
    },
    resetValidation() {
      this.$v.$reset();
    },
  },
};
</script>

<style scoped></style>
