<template>
  <div>
    <div class="inline-flex w-3/4">
      <input
        :type="inPlace ? 'radio' : 'checkbox'"
        name="link"
        class="mt-1 mr-2 pr-[14px]"
        @change="onDocumentSelectChange($event, document)"
      />
      <div class="flex flex-col">
        <span class="text-blue-600 font-sansbold">{{ document.title }}</span>
        <div>
          <search-document-state :state="document.state" />
          <span>
            | {{ $t('editor.admin.links.validFrom') }}
            {{ date }}
          </span>
        </div>
      </div>
    </div>
    <div class="inline-block w-1/4 align-top">
      {{ creator }}
    </div>
    <div
      v-for="documentBlock in blocks"
      :key="documentBlock.id"
      class="flex items-center mt-1 ml-5"
    >
      <input
        :type="inPlace ? 'radio' : 'checkbox'"
        name="link"
        class="mr-2"
        @change="onDocumentSelectChange($event, documentBlock)"
      />
      <span class="text-blue-600">{{ getText(documentBlock) }}</span>
    </div>
    <button
      v-if="document.blocks.length > 5"
      class="mt-2 mb-6 text-blue-600 underline border-0"
      @click="toggleExpand"
    >
      {{ isExpanded ? $t('general.showLess') : $t('general.showMore') }}
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SearchDocumentState from '@/components/SearchDocumentState.vue';
import dayjs from 'dayjs';
import { stripHTML } from '@/helpers/format';

export default {
  components: {
    SearchDocumentState,
  },
  props: {
    block: {
      type: Number,
      required: true,
    },
    inPlace: {
      type: Boolean,
      default: false,
    },
    document: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    ...mapState('Block', ['creators']),
    creator() {
      return (
        this.creators.find((creator) => creator.id === this.document.creator)
          ?.name || ''
      );
    },
    blocks() {
      if (!this.isExpanded) {
        return this.document.blocks.slice(0, 5);
      }
      return this.document.blocks;
    },
    date() {
      return dayjs(this.document.validFrom).format('DD.MM.YYYY');
    },
  },
  methods: {
    onDocumentSelectChange(e, result) {
      if (e.target.checked) {
        const isDocument = result.pdf !== undefined;

        const link = {
          destination: !isDocument ? result.id : null,
          linkedDocument: isDocument ? result.id : null,
          text: '',
          source: this.block,
          inPlace: this.inPlace,
          linkType: 'internal',
          display: isDocument ? result.title : result.text,
          tab: '1',
        };

        this.$emit('add', link);
      } else {
        this.$emit('remove', result.id);
      }
    },
    toggleExpand(id) {
      this.isExpanded = !this.isExpanded;
    },
    getText(block) {
      return stripHTML(block.text);
    },
  },
};
</script>
