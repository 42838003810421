<template>
  <div class="max-w-lg">
    <h3 class="text-base font-sansbold">
      {{ $t('editor.admin.links.external.header') }}
    </h3>
    <input
      v-model="title"
      class="w-full mt-2"
      :placeholder="$t('editor.admin.links.external.titlePlaceholder')"
      :disabled="inPlace"
    />
    <input
      v-model="$v.linkUrl.$model"
      class="w-full mt-2"
      :placeholder="$t('editor.admin.links.external.linkPlaceholder')"
    />
    <validation-text
      v-if="!$v.linkUrl.url && $v.linkUrl.$dirty"
      class="block error-text"
    >
      {{ $t('editor.admin.links.external.noValidLink') }}
    </validation-text>

    <button
      v-if="!inPlace"
      class="block mt-4 btn btn--primary"
      :disabled="!title || !linkUrl || !$v.linkUrl.url"
      @click="save"
    >
      {{ $t('editor.admin.links.external.addLink') }}
    </button>
  </div>
</template>

<script>
import { url } from 'vuelidate/lib/validators';
import ValidationText from '@/components/ValidationText.vue';
import { stripHTML } from '@/helpers/format';

export default {
  components: {
    ValidationText,
  },
  props: {
    block: {
      type: Number,
      required: true,
    },
    inPlace: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      title: stripHTML(this.selection),
      linkUrl: '',
    };
  },
  validations: {
    linkUrl: {
      url,
    },
  },
  watch: {
    linkUrl() {
      if (!this.inPlace) {
        return;
      }
      if (this.$v.$error) {
        this.$emit('clear');
        return;
      }
      this.addLink();
    },
  },
  methods: {
    save() {
      this.addLink();
      this.title = '';
      this.linkUrl = '';
      this.blank = false;
    },
    addLink() {
      const link = {
        url: this.linkUrl,
        text: this.title,
        inPlace: this.inPlace,
        linkType: 'external',
        source: this.block,
        display: `${this.title} (${this.linkUrl})`,
        tab: '2',
      };

      this.$emit('addLink', link);
    },
  },
};
</script>
