<template>
  <toolbar ref="toolbar" :disabled="!canEditLinks">
    <button
      class="toolbar-button"
      :title="$t('tooltips.linksLink')"
      @click="showBlockLinkModal"
    >
      <svg-icon name="link" />
    </button>
    <button
      :disabled="block.links.length === 0"
      class="toolbar-button"
      :title="$t('tooltips.linksEdit')"
      @click="showBlockLinkEditModal"
    >
      <svg-icon name="edit-link" />
    </button>

    <block-link-edit-modal
      :is-open="isEditLinkModalOpen"
      :block="block"
      @close="closeBlockLinkEditModal"
    />
  </toolbar>
</template>

<script>
import Toolbar from '@/components/admin/Toolbar.vue';
import EditorEventBus from '@/helpers/EditorEventBus';
import BlockLinkEditModal from '@/components/admin/link/BlockLinkEditModal.vue';

export default {
  name: 'AdminDocumentEditBlockToolBar',
  components: {
    Toolbar,
    BlockLinkEditModal,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditLinkModalOpen: false,
    };
  },
  computed: {
    canEditLinks() {
      switch (this.block.htmlElementReference) {
        case 'part':
        case 'chapter':
        case 'section':
        case 'article':
        case 'title':
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    showBlockLinkModal() {
      EditorEventBus.$emit('addBlockLink', this.block.id);
    },
    showBlockLinkEditModal() {
      this.isEditLinkModalOpen = true;
    },
    closeBlockLinkEditModal() {
      this.isEditLinkModalOpen = false;
    },
  },
};
</script>

<style lang="postcss" scoped>
.toolbar-button {
  @apply flex px-2 py-2 text-blue-600;
  @apply hover:bg-blue-800 hover:text-white;
  @apply disabled:text-gray-300 disabled:cursor-not-allowed disabled:pointer-events-none;
}
</style>
