<template>
  <modal :is-open="isOpen" :enable-focus-trap="false" @closeModal="closeModal">
    <template #header>
      <div class="text-4xl font-sansbold">
        {{ $t('editor.admin.links.editLinkHeader') }}
      </div>
    </template>

    <warning :text="$t('editor.admin.links.editWarning')" />

    <tabs :tabs="tabs" class="pb-1 mt-10" :destroy-hidden-tabs="false">
      <template #0>
        <edit-internal-links
          :links="internalLinks"
          @delete="deleteLink"
          @moveUp="moveUpLink"
          @moveDown="moveDownLink"
        />
      </template>
      <template #1>
        <edit-external-links
          :links="externalLinks"
          @delete="deleteLink"
          @moveUp="moveUpLink"
          @moveDown="moveDownLink"
        />
      </template>
    </tabs>
  </modal>
</template>

<script>
import Modal from '@/components/Modal';
import Tabs from '@/components/Tabs.vue';
import EditInternalLinks from './EditInternalLinks.vue';
import EditExternalLinks from './EditExternalLinks.vue';
import EditorEventBus from '@/helpers/EditorEventBus';
import { deleteLink, moveLink } from '@/services/document';
import Warning from '@/components/admin/Warning.vue';

export default {
  components: {
    Modal,
    Tabs,
    EditInternalLinks,
    EditExternalLinks,
    Warning,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tabs() {
      const internal = {
        title: this.$t('editor.admin.links.internalLinks'),
        slotName: '0',
      };
      const external = {
        title: this.$t('editor.admin.links.externalLinks'),
        slotName: '1',
      };
      return [internal, external];
    },
    internalLinks() {
      return this.block.links.filter((link) => link.linkType === 'internal');
    },
    externalLinks() {
      return this.block.links.filter((link) => link.linkType === 'external');
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    async deleteLink(link) {
      const confirmMessage = {
        title: this.$t('editor.admin.links.deleteConfirmTitle'),
        body: {
          note: this.$t('editor.admin.links.deleteConfirmBody'),
        },
      };
      const confirmed = await this.$dialog.confirm(confirmMessage);

      if (!confirmed) {
        return;
      }

      try {
        await deleteLink(link.id);

        this.$notify({
          type: 'success',
          text: this.$t('editor.admin.links.deleteNotification'),
        });

        const linkIndex = this.block.links.indexOf(link);

        EditorEventBus.$emit('updateBlockLinks', this.block.id, [
          ...this.block.links.slice(0, linkIndex),
          ...this.block.links.slice(linkIndex + 1),
        ]);
      } catch (e) {
        this.$notify({
          type: 'error',
          text: e.response.data,
        });
      }
    },
    async moveLinkToDirection(link, direction) {
      try {
        await moveLink(link, { direction });

        const fromIndex = this.block.links.indexOf(link);
        const links = this.block.links;

        links.splice(fromIndex, 1);

        if (direction === 'up') {
          links.splice(fromIndex - 1, 0, link);
        } else {
          links.splice(fromIndex + 1, 0, link);
        }

        EditorEventBus.$emit('updateBlockLinks', this.block.id, links);
      } catch (e) {
        this.$notify({
          type: 'error',
          text: e.response.data,
        });
      }
    },
    moveUpLink(link) {
      this.moveLinkToDirection(link, 'up');
    },
    moveDownLink(link) {
      this.moveLinkToDirection(link, 'down');
    },
  },
};
</script>

<style lang="postcss" scoped>
/deep/ .tabs--buttons {
  @apply justify-start;

  & .tab-btn {
    @apply mr-16;
  }
}

/deep/.link-table {
  @apply sticky top-0 pb-2 font-sansbold bg-white border-b-4 border-gray-800;
}
</style>
