import { addLink, deleteLink } from '@/services/document';
import EditorEventBus from '@/helpers/EditorEventBus';
import { getCurrentSelectionElementsFromType } from '@/helpers/selection';

export default {
  data() {
    return {
      isLinkModalOpen: false,
      selectedBlockId: 0,
    };
  },
  methods: {
    async addLinkToBlock(link, blockOffset) {
      const block = this.blocks.find(
        (block) => block.id === this.selectedBlockId
      );
      if (!block) {
        return;
      }
      if (!block.links) {
        block.links = [];
      }

      const savedLink = await addLink(link);
      block.links.push(savedLink);

      // Wrap current text with [link=ID]{TEXT}[/link]
      block.text = [
        block.text.slice(0, blockOffset.start),
        `[link=${savedLink.id}]${link.text}[/link]`,
        block.text.slice(blockOffset.end),
      ].join('');

      if (this.table) {
        EditorEventBus.$emit('updateBlockTable', block.id, block.text);
      } else {
        EditorEventBus.$emit('updateBlockText', block.id, block.text);
      }
    },
    async updateLink(blockId, currentLink) {
      currentLink.destination = blockId;
      const savedLink = await updateLink(currentLink);
      const link = this.findLink(savedLink.id);
      if (!link) {
        return;
      }
      link.destination = savedLink.destination;
      link.text = savedLink.text;

      EditorEventBus.$emit('updateBlockLinks', block.id, block.links);
    },
    async deleteLinkInternal(linkToDelete) {
      try {
        await deleteLink(linkToDelete.id);
      } catch {}

      const block = this.blocks.find(
        (block) => block.id === linkToDelete.source
      );
      if (!block) {
        return;
      }

      for (let i = 0; i < block.links.length; i++) {
        const link = block.links[i];
        if (link.id === linkToDelete.id) {
          // Remove link from links array
          block.links.splice(i, 1);

          const regex = new RegExp(
            `\\[link=${link.id}\\](.*?)\\[\\/link\\]`,
            'gm'
          );
          let match = null;
          let text = block.text;

          // Get all links text with the given link id
          while ((match = regex.exec(block.text))) {
            const linkText = match[1];

            // Replace the linkText with the text without the [link] element
            text = text.replace(
              `[link=${link.id}]${linkText}[/link]`,
              linkText
            );
          }
          block.text = text;

          if (this.table) {
            EditorEventBus.$emit('updateBlockTable', block.id, block.text);
          } else {
            EditorEventBus.$emit('updateBlockText', block.id, block.text);
          }
          break;
        }
      }
    },
    async saveLinks(links, offset) {
      const promises = [];
      for (const link of links) {
        // Delete our needed properties from the object
        const linkCopy = { ...link, display: undefined, tab: undefined };
        if (link.inPlace) {
          await this.addLinkToBlock(linkCopy, offset);
          continue;
        }
        const block = this.blocks.find((block) => block.id === link.source);

        const savedLink = await addLink(linkCopy);
        block.links.push(savedLink);

        // Sort links by linkType and by order
        block.links = block.links.sort((a, b) => {
          if (a.linkType === b.linkType) {
            return a.order - b.order;
          }
          return a.linkType === 'internal' ? -1 : 1;
        });
      }

      this.$notify({
        type: 'success',
        text: this.$t('editor.admin.links.createdNotification'),
      });
      this.isLinkModalOpen = false;
    },
    deleteInPlaceLink() {
      const selection = window.getSelection().getRangeAt(0);
      const htmlLinks = getCurrentSelectionElementsFromType('a');
      htmlLinks.forEach((htmlLink) => {
        const currentLink = this.getLink(htmlLink);

        if (currentLink) {
          this.deleteLinkInternal(currentLink);
        }
      });
    },
    getLink(htmlLink) {
      const linkId = parseInt(htmlLink.getAttribute('data-link-id'));

      // Find currentLink
      for (let link of this.links) {
        if (link.id === linkId) {
          return link;
        }
      }
      return null;
    },
  },
};
