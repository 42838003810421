<template>
  <div class="relative">
    <div class="flex">
      <block-table-of-contents
        :options="tableOfContents"
        class="flex-1 mr-16"
        @input="scrollToBlock"
      />
      <!-- Empty div to keep the layout -->
      <div class="flex-1" />
    </div>

    <div class="relative flex mt-10">
      <div class="flex-1 mr-16">
        <admin-pdf-view
          v-if="showPdfView"
          class="h-[90vh]"
          :document="document"
        />
        <virtual-list
          v-else
          ref="leftList"
          class="max-h-[90vh] overflow-y-auto break-words bg-gray-50 p-4"
          data-key="id"
          item-class="display: flex;"
          :data-sources="blocks"
          :data-component="blockRenderComponent"
          :keeps="50"
        />
      </div>
      <div class="flex-1">
        <div id="blocks">
          <virtual-list
            ref="rightList"
            class="
              overflow-x-visible overflow-y-auto
              scroll
              max-h-[90vh]
              ml-8
              bg-white
              break-words
            "
            item-class="display: flex;"
            data-key="id"
            :data-sources="blocks"
            :data-component="blockComponent"
            :keeps="50"
            :extra-props="{
              blocksLength: blocks.length,
              document,
              blockList,
            }"
          >
          </virtual-list>
        </div>
      </div>
      <template v-if="!showPdfView">
        <sync-scroll-button class="absolute left-[-96px]" @sync="syncLeft" />
        <sync-scroll-button class="absolute right-[-96px]" @sync="syncRight" />
      </template>

      <admin-render-view-switch
        class="absolute left-[-96px] top-[120px]"
        @changeView="changeView"
      />
    </div>
    <block-context-menu />
  </div>
</template>

<script>
import AdminPdfView from '@/components/admin/AdminPdfView';
import AdminDocumentStructuringBlock from './AdminDocumentStructuringBlock.vue';
import { mapMutations } from 'vuex';
import { getHTMLElementReferences, getBlockCategories } from '@/services/block';
import VirtualList from 'vue-virtual-scroll-list';
import AdminRenderViewSwitch from '@/components/admin/AdminRenderViewSwitch.vue';
import AdminEditorBlock from '@/components/admin/AdminEditorBlock';
import BlockTableOfContents from '@/components/BlockTableOfContents.vue';
import BlockContextMenu from '@/components/admin/structuring/BlockContextMenu.vue';
import SyncScrollButton from '@/components/admin/structuring/SyncScrollButton.vue';

export default {
  components: {
    AdminPdfView,
    VirtualList,
    AdminRenderViewSwitch,
    BlockTableOfContents,
    BlockContextMenu,
    SyncScrollButton,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    blockList: {
      type: Object,
      required: true,
    },
    blocks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      blockComponent: AdminDocumentStructuringBlock,
      showPdfView: true,
      blockRenderComponent: AdminEditorBlock,
    };
  },
  computed: {
    tableOfContents() {
      return this.blockList.getTableOfContents();
    },
  },
  async mounted() {
    const elementReferences = await getHTMLElementReferences();
    this.setElementReferences(elementReferences.data);
    const blockCategories = await getBlockCategories();
    this.setCategories(blockCategories);
  },
  methods: {
    ...mapMutations('Block', ['setElementReferences', 'setCategories']),
    changeView(mode) {
      this.showPdfView = mode === 'pdf';
    },
    scrollToBlock(block) {
      if (this.$refs.leftList) {
        this.$refs.leftList.scrollToIndex(block.index);
      }
      this.$refs.rightList.scrollToIndex(block.index);
    },
    syncRight() {
      this.syncScrolling(this.$refs.leftList, this.$refs.rightList);
    },
    syncLeft() {
      this.syncScrolling(this.$refs.rightList, this.$refs.leftList);
    },
    syncScrolling(from, to) {
      const boundingClientRect = from.$el.getBoundingClientRect();

      const childs = from.$el.children[0].children;
      const index = this.getIndexFromCurrentElementAtTop(
        childs,
        boundingClientRect
      );
      to.scrollToIndex(from.range.start + index);
    },
    getIndexFromCurrentElementAtTop(childs, boundingClientRect) {
      for (let i = 0; i < childs.length; i++) {
        const child = childs[i];
        const childBoundingClientRect = child.getBoundingClientRect();
        // Check if this element is visible on screen (add 10px of margins)
        if (
          childBoundingClientRect.top + childBoundingClientRect.height >
          boundingClientRect.top + 10
        ) {
          return i;
        }
      }
    },
  },
};
</script>
