<template>
  <div
    ref="toolbar"
    class="toolbar--wrapper"
    :class="{ 'toolbar--disabled': disabled }"
  >
    <button class="toolbar--button" :disabled="disabled" @click="toggleToolBar">
      <svg-icon name="menu" class="svg-icon--lg" />
    </button>
    <div v-if="isToolBarOpen" class="toolbar-popup">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toolbar',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isToolBarOpen: false,
    };
  },
  methods: {
    toggleToolBar() {
      this.isToolBarOpen = !this.isToolBarOpen;
      window.requestAnimationFrame(() => {
        if (this.isToolBarOpen) {
          window.addEventListener('click', this.hideToolBar, { capture: true });
        } else {
          window.removeEventListener('click', this.hideToolBar);
        }
      });
      if (this.isToolBarOpen) {
        this.$emit('open');
      } else {
        this.$emit('hide');
      }
    },
    hideToolBar(event) {
      let isOutsideClick = true;
      const possibleElements = document.querySelectorAll(
        '.popover, .modal, .dg-open'
      );
      possibleElements.forEach((element) => {
        if (element.contains(event.target)) {
          isOutsideClick = false;
        }
      });
      if (this.$refs.toolbar && this.$refs.toolbar.contains(event.target)) {
        isOutsideClick = false;
      }
      if (!isOutsideClick) {
        return;
      }
      this.$emit('hide');
      this.isToolBarOpen = false;
    },
  },
};
</script>

<style lang="postcss" scoped>
.toolbar--wrapper {
  @apply relative;
  @apply flex flex-row-reverse;

  & .toolbar--button {
    @apply flex items-center justify-center w-6 h-6 text-blue-600 disabled:text-gray-300 disabled:cursor-not-allowed;
  }
}

.toolbar-popup {
  @apply absolute right-[24px] top-[-6px];
  @apply z-10;
  @apply flex items-center justify-center;
  @apply text-blue-600 bg-gray-50;
  @apply shadow-md;
}
</style>
