<template>
  <div class="bg-blue-600 bg-opacity-[10%] p-3 flex max-w-lg rounded">
    <svg-icon name="attention" class="w-5 h-5 text-blue-600" />
    <span class="flex-1 ml-4">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
