<template>
  <button class="rounded-tr switch-btn" @click="syncScrolling">
    <svg-icon name="sync-scroll" class="svg-icon--lg" />
  </button>
</template>

<script>
export default {
  methods: {
    syncScrolling() {
      this.$emit('sync');
    },
  },
};
</script>

<style lang="postcss" scoped>
.switch-btn {
  @apply w-9 h-9;
  @apply flex items-center justify-center;
  @apply bg-gray-50 text-blue-800;
}
</style>
