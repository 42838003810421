<template>
  <div>
    <div class="flex items-center h-full px-4 py-2 bg-blue-600 max-h-11">
      <div class="links-title">
        {{ $t('editor.admin.linkList.title', { text: title }) }}
      </div>
      <button class="ml-8" @click="toggleLinkBox">
        <svg-icon class="text-white min-w-6 min-h-6" :name="linkBoxIcon" />
      </button>
    </div>
    <div
      v-if="isLinkBoxExpanded"
      class="px-5 py-3 rounded-b shadow-md bg-gray-50"
    >
      <span class="font-sansbold">
        {{ $t('editor.admin.linkList.linksTitle') }}
      </span>
      <div v-for="link in links" :key="link.id" class="mt-2">
        <a
          :href="getLinkHref(link)"
          class="text-blue-600"
          target="_blank"
          :[link.linkType]="true"
        >
          {{ getLinkText(link) }}
        </a>
        <span
          v-if="link.destination"
          class="block overflow-hidden overflow-ellipsis whitespace-nowrap"
        >
          {{ link.destination.documentTitle }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getLinkHref } from '@/helpers/links';

export default {
  props: {
    links: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLinkBoxExpanded: false,
    };
  },
  computed: {
    linkBoxIcon() {
      return this.isLinkBoxExpanded ? 'chevron-up' : 'chevron-down';
    },
  },
  methods: {
    toggleLinkBox() {
      this.isLinkBoxExpanded = !this.isLinkBoxExpanded;
    },
    getLinkHref(link) {
      return getLinkHref(link);
    },
    getLinkText(link) {
      if (link.destination) {
        return link.destination.title;
      }
      if (link.linkedDocument) {
        return link.linkedDocument.title;
      }
      return link.text;
    },
  },
};
</script>

<style lang="postcss" scoped>
.links-title {
  @apply w-full;
  @apply overflow-hidden font-sansbold text-white whitespace-nowrap overflow-ellipsis;
}
</style>
