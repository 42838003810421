const getParent = (range) => {
  let parent = range.commonAncestorContainer;

  while (parent.nodeName !== 'P' && parent.nodeName !== 'DIV') {
    parent = parent.parentNode;
  }
  return parent;
};

const hasCurrentSelectionGivenHtmlElement = (htmlTag) => {
  const range = window.getSelection().getRangeAt(0);

  let parent = getParent(range);

  let isBold = false;

  const checkChild = (child) => {
    if (
      child.nodeName === htmlTag.toUpperCase() &&
      window.getSelection().containsNode(child, true)
    ) {
      isBold = true;
      return;
    }
    child.childNodes.forEach((innerChild) => {
      checkChild(innerChild);
    });
  };

  checkChild(parent);

  return isBold;
};

const getCurrentSelectionElementsFromType = (htmlTag) => {
  const range = window.getSelection().getRangeAt(0);

  let parent = getParent(range);

  let htmlElements = [];

  const checkChild = (child) => {
    if (
      child.nodeName === htmlTag.toUpperCase() &&
      window.getSelection().containsNode(child, true)
    ) {
      htmlElements.push(child);
      return;
    }
    child.childNodes.forEach((innerChild) => {
      checkChild(innerChild);
    });
  };

  checkChild(parent);

  return htmlElements;
};

export {
  hasCurrentSelectionGivenHtmlElement,
  getCurrentSelectionElementsFromType,
};
