<template>
  <div class="admin-document--publish">
    <!-- Document validity -->
    <div>
      <p class="font-sansbold">
        {{ $t('editor.admin.publishDocument.validity') }}
      </p>
      <div class="flex">
        <p class="mr-44">
          {{ $t('editor.admin.publishDocument.validFrom') }}:
          <span>{{ validFrom }}</span>
        </p>
        <p>
          {{ $t('editor.admin.publishDocument.validTo') }}:
          <span>{{ validTo }}</span>
        </p>
      </div>
    </div>
    <!-- Document state -->
    <div class="mt-8">
      <p class="font-sansbold">
        {{ $t('editor.admin.publishDocument.state') }}
      </p>
      <document-state
        :document="document"
        :has-labels="true"
        class="flex items-center"
      />
    </div>
    <!-- Document publish date -->
    <div class="mt-8">
      <p class="font-sansbold">
        {{ $t('editor.admin.publishDocument.publishDate') }}
      </p>
      <p>{{ $t('editor.admin.publishDocument.publishAt') }}</p>
      <date-picker
        v-model="publishDate"
        format="DD.MM.YYYY"
        class="date-picker py-2"
        :placeholder="$i18n.t('editor.admin.publishDocument.placeholder')"
        :append-to-body="false"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/Datepicker.vue';
import DocumentState from '@/components/DocumentState';
import 'vue2-datepicker/index.css';
import dayjs from 'dayjs';
import EditorEventBus from '@/helpers/EditorEventBus';
import { formatDate } from '@/helpers/DateFormatters';
import { publishDocument } from '@/services/document';

export default {
  name: 'AdminDocumentPublish',
  components: {
    DatePicker,
    DocumentState,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      publishDate: '',
    };
  },
  computed: {
    validFrom() {
      if (this.document.validFrom) return formatDate(this.document.validFrom);
      return this.$t('editor.admin.publishDocument.noValidTo');
    },
    validTo() {
      if (this.document.validTo) return formatDate(this.document.validTo);
      return this.$t('editor.admin.publishDocument.noValidTo');
    },
  },
  mounted() {
    this.publishDate = this.document.publishDate
      ? dayjs(this.document.publishDate).$d
      : '';
    EditorEventBus.$on('publishDocument', this.publishDocument);
  },
  beforeDestroy() {
    EditorEventBus.$off('publishDocument', this.publishDocument);
  },
  methods: {
    async publishDocument() {
      if (!this.publishDate) {
        this.publishDate = dayjs().$d;
      }
      try {
        await publishDocument(this.document.id, {
          ...this.document,
          publishDate: formatDate(this.publishDate, 'YYYY-MM-DD'),
        });
        this.$notify({
          type: 'success',
          text: this.$t('notifications.publishSuccess'),
        });
        EditorEventBus.$emit('reloadDocument');
      } catch (e) {
        this.$notify({
          type: 'error',
          text:
            e.response?.data?.message || this.$t('notifications.publishError'),
        });
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.admin-document--publish * {
  @apply text-lg;
}
</style>
