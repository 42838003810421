<template>
  <div>
    <LoadingSpinner
      v-if="document && !document.importFinished"
      :importing="true"
    />
    <LoadingSpinner v-else-if="loading" :importing="false" />
    <div class="flex items-center justify-between mt-20">
      <admin-language-tabs
        :document="document"
        :active-language="activeLanguageTab"
        @removeLanguageTab="removeLanguageTab"
      />
      <router-link
        to="/admin"
        class="flex items-center text-blue-600 no-underline hover:text-blue-800"
      >
        <svg-icon name="arrow-left" class="w-5 h-5 mr-2" />
        {{ $t('editor.backToDocumentList') }}
      </router-link>
    </div>

    <div v-if="document" class="flex flex-col w-full mb-12 bg-white shadow-md">
      <div v-if="$route.params.taskId">
        <web-socket
          :key="$route.params.taskId"
          :task-id="$route.params.taskId"
          @done="uploadDone"
        />
      </div>
      <tabs
        ref="tabs"
        :tabs="tabs"
        :document="document"
        :disabled-tabs="disabledTabs"
        class="px-24 pt-12 pb-1"
        @setActiveTab="setActiveTab"
      >
        <h2 slot="header" class="max-w-4xl mb-8 text-3xl leading-tight">
          {{ documentTitle }}
        </h2>
        <template #0>
          <div
            v-if="isPdfDeletable && !document.isLanguageAddable"
            class="mb-10"
          >
            <button class="btn btn--primary" @click="deletePdf">
              {{ $t('editor.copiedDocument.deleteDocument') }}
            </button>
          </div>
          <div v-if="document.isLanguageAddable && isMultilang" class="mb-10">
            {{ $t('editor.initialDocument.deleteDocumentVersion') }}
          </div>
          <div v-if="document.isLanguageAddable && !isMultilang" class="mb-10">
            {{ $t('editor.initialDocument.deleteDocument') }}
          </div>
          <admin-document-metadata
            :document="document"
            :active-language="activeLanguageTab"
            @setValidation="setMetadataFormValidation"
          />
        </template>
        <template #1>
          <admin-document-structuring
            :document="document"
            :block-list="blockList"
            :blocks="blocks"
          />
        </template>
        <template #2>
          <admin-document-edit
            :document="document"
            :block-list="blockList"
            :blocks="blocks"
          />
        </template>
        <template #3>
          <admin-document-description
            v-model="document.description"
            :document="document"
            :active-language="activeLanguageTab"
          />
        </template>
        <template #4>
          <admin-document-publish class="mb-8" :document="document" />
        </template>
      </tabs>
      <div class="shadow-divider" />
      <div class="flex items-center justify-end px-24 py-12">
        <button
          v-if="activeTab === '0'"
          :disabled="isSaveTabButtonDisabled"
          class="mr-6 btn btn--secondary"
          @click="saveMetadata"
        >
          {{ $t('general.save') }}
        </button>

        <span v-if="missingPdfsHelpText" class="mr-6 text-primary-500">
          {{ missingPdfsHelpText }}
        </span>

        <button
          class="btn btn--primary"
          :disabled="isNextButtonDisabled"
          @click="goToNextTab"
        >
          {{ saveTabButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDocumentById,
  deletePdf,
  getMissingPdfs,
} from '@/services/document';
import Tabs from '@/components/Tabs';
import AdminDocumentMetadata from '@/components/admin/AdminDocumentMetadata';
import AdminDocumentStructuring from '@/components/admin/structuring/AdminDocumentStructuring';
import AdminDocumentEdit from '@/components/admin/AdminDocumentEdit';
import EditorEventBus from '@/helpers/EditorEventBus';
import WebSocket from '@/components/WebSocket.vue';
import AdminLanguageTabs from '@/components/admin/AdminLanguageTabs';
import AdminDocumentPublish from '@/components/admin/publish/AdminDocumentPublish';
import { flattenTheTree } from '@/helpers/blockList';
import Blocks from '@/helpers/blocks';
import LoadingSpinner from '@/components/LoadingSpinner';
import AdminDocumentDescription from '@/components/admin/AdminDocumentDescription';
import { mapMutations, mapGetters } from 'vuex';

export default {
  components: {
    Tabs,
    AdminDocumentMetadata,
    AdminDocumentStructuring,
    AdminDocumentEdit,
    WebSocket,
    AdminLanguageTabs,
    AdminDocumentPublish,
    LoadingSpinner,
    AdminDocumentDescription,
  },
  data() {
    return {
      document: null,
      showLinkModal: false,
      currentBlockId: 0,
      taskDone: false,
      blockList: null,
      activeLanguageTab: null,
      isMetadataFormValid: false,
      activeTab: null,
      blocks: null,
      missingPdfs: [],
    };
  },
  computed: {
    ...mapGetters('Loading', ['loading']),
    isPdfDeletable() {
      return this.document.pdf && this.document.state !== 'published';
    },
    isMultilang() {
      return this.document.documentLanguages.length !== 1;
    },
    isSaveTabButtonDisabled() {
      if (this.activeTab === '0') {
        return this.isMetadataFormValid;
      }
      if (this.activeTab === '4') {
        return this.document.state === 'published';
      }
      return false;
    },
    isNextButtonDisabled() {
      if (this.activeTab === '0') {
        // disable if any of the required field are null
        return this.isMetadataFormValid;
      }
      if (this.activeTab === '3') {
        return !this.document.description;
      }
      if (this.activeTab === '4') {
        return (
          this.document.publishDate !== null || this.missingPdfs.length > 0
        );
      }
      return false;
    },
    disabledTabs() {
      if (!this.taskDone) return ['1', '2', '3', '4'];
      if (this.isNextButtonDisabled && this.activeTab === '0')
        return ['1', '2', '3', '4'];

      return [];
    },
    saveTabButtonText() {
      switch (this.activeTab) {
        case '0':
          return this.$t('editor.nextButton.metadata');
        case '1':
          return this.$t('editor.nextButton.structuring');
        case '2':
          return this.$t('editor.nextButton.links');
        case '3':
          return this.$t('editor.nextButton.documentDescription');
        case '4':
          return this.$t('editor.nextButton.publish');
        default:
          return this.$t('general.save');
      }
    },
    rootBlock() {
      return this.document.block[0];
    },
    tabs() {
      const metadata = {
        title: this.$t('editor.tabs.metadata'),
        slotName: '0',
      };
      const structure = {
        title: this.$t('editor.tabs.structure'),
        slotName: '1',
      };
      const edit = { title: this.$t('editor.tabs.links'), slotName: '2' };

      const editDescription = {
        title: this.$t('editor.tabs.editDescription'),
        slotName: '3',
      };

      const publish = { title: this.$t('editor.tabs.publish'), slotName: '4' };

      if (this.document.importType !== 'full') {
        return [metadata, editDescription, publish];
      }
      return [metadata, structure, edit, publish];
    },
    documentTitle() {
      if (this.isCopiedDocument) {
        return this.$i18n.t('general.isCopyLabel', {
          documentTitle: this.document.title ? this.document.title : '',
        });
      }
      return this.document.title ? this.document.title : '';
    },
    isCopiedDocument() {
      if (this.document.importType !== 'full' || !this.rootBlock) return false;
      return !!(this.document.state === 'draft' && this.rootBlock.hasHistory);
    },
    missingPdfsHelpText() {
      if (this.activeTab !== '4') return '';

      // convert all language shorthands to upper case
      const languages = this.missingPdfs.map((lang) => lang.toUpperCase());

      if (!languages.length) return '';

      let langStr = languages[0];

      if (languages.length > 1) {
        for (let i = 1; i < languages.length - 1; i++) {
          langStr += `, ${languages[i]}`;
        }

        langStr += ` ${this.$t('general.and')} ${
          languages[languages.length - 1]
        }`;
      }

      return this.$t('editor.nextButton.publishHelpText', {
        languages: langStr,
      });
    },
  },
  created() {
    if (!this.$route.params.taskId) {
      this.taskDone = true;
    }
  },
  async mounted() {
    this.addLoadingState();
    // Either take the language out of the uri or the first of the set document languages for default.
    this.activeLanguageTab = this.$route.query.activeLang || null;

    const id = this.$route.params.id;
    const document = await getDocumentById(id, {
      lang: this.activeLanguageTab,
    });
    this.document = document;

    this.missingPdfs = await getMissingPdfs(this.document.id);

    if (!this.activeLanguageTab) {
      this.activeLanguageTab = document.documentLanguages[0];
      this.reloadDocument();
    }

    if (this.document.importType === 'full') {
      this.updateBlockList();
    }
    this.registerEvents();
    this.removeLoadingState();
  },
  beforeDestroy() {
    this.removeLoadingState();
    this.destroyEvents();
  },
  methods: {
    ...mapMutations('Loading', ['addLoadingState', 'removeLoadingState']),
    setActiveTab(activeTab) {
      this.activeTab = activeTab;
    },
    notify(text, type) {
      this.$notify({
        text,
        type,
      });
    },
    async repealBlock(blockId) {
      try {
        await this.blockList.repealBlock(blockId);
      } catch (e) {
        if (e.response?.data?.message) {
          this.$notify({
            type: 'error',
            text: e.response.data.message,
          });
        }
      }
    },
    updateBlockText(blockId, text) {
      this.blockList.updateBlockPropertyName(blockId, 'text', text);
    },
    updateBlockLinks(blockId, links) {
      this.blockList.updateBlockPropertyName(blockId, 'links', links);
    },
    updateBlockFormat(blockId, format) {
      this.blockList.updateBlockFormat(blockId, format);
      this.blocks = this.blockList.getBlocks();
    },
    updateBlockCategories(blockId, categories) {
      this.blockList.updateBlockPropertyName(blockId, 'categories', categories);
    },
    changeBlockFormat(payload) {
      const newBlock = {
        id: payload.id,
        validFrom: payload.value.validFrom,
        validTo: payload.value.validTo,
      };
      this.blockList.updateBlock(newBlock);
    },
    async deleteBlock(blockId) {
      try {
        await this.blockList.deleteBlock(blockId);
        this.blocks = this.blockList.getBlocks();
      } catch (e) {
        if (e.response?.data?.message) {
          this.$notify({
            type: 'error',
            text: e.response.data.message,
          });
        }
      }
    },
    restoreBlock(blockId) {
      this.blockList.restoreBlock(blockId);

      this.blocks = this.blockList.getBlocks();
    },
    createNewBlock(newBlock) {
      this.blockList.createBlock(newBlock);
      this.blocks = this.blockList.getBlocks();
    },
    updateBlockList() {
      const flatBlockList = flattenTheTree(this.rootBlock);
      this.blockList = new Blocks(flatBlockList);
      this.blocks = this.blockList.getBlocks();
    },
    registerEvents() {
      EditorEventBus.$on('notify', this.notify);

      EditorEventBus.$on('repealBlock', this.repealBlock);

      EditorEventBus.$on('changeLanguageTab', this.changeLanguageTab);

      EditorEventBus.$on('addNewLanguageTab', this.addNewLanguageTab);

      EditorEventBus.$on('updateBlockText', this.updateBlockText);

      EditorEventBus.$on('updateBlockLinks', this.updateBlockLinks);

      EditorEventBus.$on('updateBlockFormat', this.updateBlockFormat);

      EditorEventBus.$on('updateBlockCategories', this.updateBlockCategories);

      EditorEventBus.$on('changeBlockFormat', this.changeBlockFormat);

      EditorEventBus.$on('deleteBlock', this.deleteBlock);

      EditorEventBus.$on('restoreBlock', this.restoreBlock);

      EditorEventBus.$on('createNewBlock', this.createNewBlock);

      EditorEventBus.$on('reloadDocument', this.reloadDocument);

      EditorEventBus.$on('moveBlock', this.moveBlock);
    },
    destroyEvents() {
      EditorEventBus.$off('notify', this.notify);

      EditorEventBus.$off('repealBlock', this.repealBlock);

      EditorEventBus.$off('updateBlockText', this.updateBlockText);

      EditorEventBus.$off('updateBlockLinks', this.updateBlockLinks);

      EditorEventBus.$off('updateBlockFormat', this.updateBlockFormat);

      EditorEventBus.$off('updateBlockCategories', this.updateBlockCategories);

      EditorEventBus.$off('changeBlockFormat', this.changeBlockFormat);

      EditorEventBus.$off('deleteBlock', this.deleteBlock);

      EditorEventBus.$off('restoreBlock', this.restoreBlock);

      EditorEventBus.$off('createNewBlock', this.createNewBlock);

      EditorEventBus.$off('reloadDocument', this.reloadDocument);

      EditorEventBus.$off('changeLanguageTab', this.changeLanguageTab);

      EditorEventBus.$off('addNewLanguageTab', this.addNewLanguageTab);

      EditorEventBus.$off('moveBlock', this.moveBlock);
    },
    async uploadDone() {
      this.taskDone = true;

      // reload document to get the imported blocks (only overwrite the blocks)
      const document = await getDocumentById(this.document.id, {
        lang: this.activeLanguageTab,
      });

      this.document = document;
      if (this.document.importFinished) {
        this.removeLoadingState();
      }
      if (this.rootBlock) {
        this.updateBlockList();
      }
    },

    async goToNextTab() {
      if (this.activeTab === '0') {
        const saveDialogAccepted = await this.saveMetadata();
        if (!saveDialogAccepted) return;
      }

      if (this.activeTab === '4') {
        let confirmNote = this.$t('admin.publishDocumentConfirmation.body');

        // If the document is in its initial version we have to war the user
        // that he can not add or remove languages anymore after the initial
        // publication of the document.
        if (this.document.isInitialVersion) {
          confirmNote = this.$t(
            'admin.publishDocumentConfirmation.bodyInitial'
          );
        }

        const confirmMessage = {
          title: this.$t('admin.publishDocumentConfirmation.title'),
          body: {
            note: confirmNote,
          },
        };
        const options = {
          okText: this.$t('admin.publishDocumentConfirmation.yes'),
          cancelText: this.$t('admin.publishDocumentConfirmation.no'),
        };
        this.$dialog.confirm(confirmMessage, options).then(() => {
          EditorEventBus.$emit('publishDocument');
        });
      }

      if (this.activeTab === '3') {
        EditorEventBus.$emit('saveDocumentDescription');
      }
      for (let i = 0; i < this.tabs.length; i++) {
        const tab = this.tabs[i];
        if (tab.slotName === this.activeTab) {
          this.$refs.tabs.selectTab(this.tabs[i + 1].slotName);
          return;
        }
      }
    },
    async saveMetadata() {
      if (this.document.documentLanguages.length > 1) {
        const confirmMessage = {
          title: this.$t('admin.saveMetadataConfirmation.title'),
          body: {
            note: this.$t('admin.saveMetadataConfirmation.body'),
          },
        };
        const options = {
          okText: this.$t('admin.saveMetadataConfirmation.yes'),
          cancelText: this.$t('admin.saveMetadataConfirmation.no'),
        };

        try {
          await this.$dialog.confirm(confirmMessage, options);
        } catch {
          // dialog declined
          return false;
        }

        EditorEventBus.$emit('saveTab');
      } else {
        EditorEventBus.$emit('saveTab');
      }

      return true;
    },
    async reloadDocument() {
      this.document = await getDocumentById(this.document.id, {
        lang: this.activeLanguageTab,
      });
      if (this.document.importFinished) {
        this.removeLoadingState();
      }

      this.missingPdfs = await getMissingPdfs(this.document.id);
    },

    async addNewLanguageTab(lang, taskId) {
      this.taskDone = false;
      this.document.documentLanguages.push(lang);
      this.activeLanguageTab = lang;
      // Show metadata tab of new language version.
      this.$refs.tabs.selectTab('0');
      await this.reloadDocument();
      if (!this.document.importFinished) {
        this.addLoadingState();
      }
      await this.$router.replace({
        name: 'adminEditor',
        params: {
          taskId: taskId,
          id: this.document.id,
        },
        query: { activeLang: lang },
      });
    },

    async removeLanguageTab(lang) {
      let index = this.document.documentLanguages.indexOf(lang);
      if (index !== -1) {
        this.document.documentLanguages.splice(index, 1);
      }
      await this.changeLanguageTab(this.document.documentLanguages[0]);
    },

    async changeLanguageTab(lang) {
      this.activeLanguageTab = lang;
      await this.$router.replace({
        name: 'adminEditor',
        query: { activeLang: lang },
      });
      await this.reloadDocument();
      this.updateBlockList();
    },
    async deletePdf() {
      const confirmMessage = {
        title: this.$t('admin.deletePdfConfirmation.title'),
        body: {
          note: this.$t('admin.deletePdfConfirmation.body'),
        },
      };
      const options = {
        okText: this.$t('admin.deletePdfConfirmation.yes'),
        cancelText: this.$t('admin.deletePdfConfirmation.no'),
      };
      this.$dialog.confirm(confirmMessage, options).then(async () => {
        await deletePdf(this.document.id, this.activeLanguageTab);
        EditorEventBus.$emit('reloadDocument');
        this.$notify({
          type: 'success',
          text: this.$t('editor.deletePdf.success').toString(),
        });
      });
    },
    setMetadataFormValidation(isValid) {
      this.isMetadataFormValid = isValid;
    },
    moveBlock(response) {
      this.blockList.moveBlock(response);
      this.blocks = this.blockList.getBlocks();
    },
  },
};
</script>

<style lang="postcss">
#blocks {
  & th,
  & td {
    min-width: 50px;
    min-height: 50px;
    height: 50px;
    vertical-align: top;
  }
}

.shadow-divider {
  @apply mt-6;
  @apply h-[1px];
  box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.25);
}
</style>
