import { render, staticRenderFns } from "./AdminEditorLinkModal.vue?vue&type=template&id=39161956&scoped=true"
import script from "./AdminEditorLinkModal.vue?vue&type=script&lang=js"
export * from "./AdminEditorLinkModal.vue?vue&type=script&lang=js"
import style0 from "./AdminEditorLinkModal.vue?vue&type=style&index=0&id=39161956&prod&lang=postcss&scoped=true"
import style1 from "./AdminEditorLinkModal.vue?vue&type=style&index=1&id=39161956&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39161956",
  null
  
)

export default component.exports