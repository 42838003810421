import { unpack } from './helpers';
import { userApi } from './api';

import { Result } from '../models/Result.model';

function camelToUnderscore(key) {
  return key.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export const searchResults = async (term, exact, filters, page) => {
  let filter_query = '';

  if (filters) {
    const params = [];
    Object.keys(filters).forEach((key) => {
      if (Array.isArray(filters[key])) {
        filters[key].forEach((filter) => {
          params.push(`${key}=${filter}`);
        });
      } else {
        params.push(`${key}=${filters[key]}`);
      }
    });
    filter_query += `&${params.join('&')}`;
  }

  let search_url = `/elastic/?${filter_query}&page=${page}`;
  if (term) {
    search_url = `/elastic/?search=${term}${filter_query}&page=${page}`;
  }
  if (exact) {
    search_url += '&exact=true';
  }
  try {
    const response = await unpack(userApi.get(search_url));
    let documents = [];
    if (response.search) {
      documents = response.search.results.map((payload) => {
        return new Result(payload);
      });
    }
    return {
      directSearch: response.directSearch,
      documents: documents,
      response: response.search,
    };
  } catch (err) {
    console.info(err);
  }
};

export const getSearchLog = async () => {
  const response = await userApi.get('/search-log/');
  return response.data;
};

export const deleteSearchLog = async () => {
  const response = await userApi.delete(`/search-log/delete/all/`);
  return response.data;
};

export const deleteSearchLogEntry = async (id) => {
  const response = await userApi.delete(`/search-log/${id}/`);
  return response.data;
};
