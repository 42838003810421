<template>
  <modal :is-open="isOpen" @closeModal="cancel">
    <div slot="header">
      <h3 class="text-h1">{{ $t('blockStructure.repealBlock.header') }}</h3>
    </div>
    <div>
      <p class="italic text-base">{{ block.text }}</p>

      <p class="mt-4 text-base">
        {{ $t('blockStructure.repealBlock.general') }}
      </p>
      <p v-if="block.hasHistoricalChildren" class="text-base">
        {{ $t('admin.affectsHistory') }}
      </p>
    </div>
    <div slot="footer" class="flex justify-between">
      <button type="button" class="btn" @click="cancel">
        {{ $t('blockStructure.createBlock.cancel') }}
      </button>
      <button type="button" class="btn btn--primary" @click="confirm">
        {{ $t('blockStructure.repealBlock.confirm') }}
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal';

export default {
  name: 'RepealBlock',
  components: {
    Modal,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('submit', this.block.id);
    },
  },
};
</script>

<style scoped></style>
