<template>
  <div>
    <div class="mt-6 overflow-y-auto max-h-[28rem] table--scroll-container">
      <div
        class="
          sticky
          top-0
          pb-2
          bg-white
          border-b-4 border-gray-800
          font-sansbold
        "
      >
        <div class="inline-block w-3/4">
          {{ $t('editor.admin.links.searchResultTitle') }}
        </div>
        <div class="inline-block w-1/4">
          {{ $t('editor.admin.links.searchResultCreator') }}
        </div>
      </div>
      <div>
        <div class="mt-4 mb-2 border-b border-gray-800">
          <div class="inline-flex w-3/4">
            <span class="text-blue-600 font-sansbold">
              {{ document.title }}
            </span>
          </div>
          <div class="inline-block w-1/4 align-top">
            {{ creator }}
          </div>
          <div
            v-for="contentBlock in tableOfContents"
            :key="contentBlock.id"
            class="flex items-center mt-1 ml-5"
          >
            <input
              type="radio"
              name="link"
              class="mr-2"
              @change="onSelectChange($event, contentBlock)"
            />
            <span class="text-blue-600">{{ getText(contentBlock) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { searchResults } from '@/services/Search.service.js';
import { mapState } from 'vuex';
import { stripHTML } from '@/helpers/format';
import { textWithoutLinks } from '@/helpers/links';

export default {
  props: {
    block: {
      type: Number,
      required: true,
    },
    inPlace: {
      type: Boolean,
      default: false,
    },
    document: {
      type: Object,
      required: true,
    },
    blockList: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      results: [],
      selectedDocuments: [],
      resultsPagination: {},
      currentPage: 1,
    };
  },
  computed: {
    ...mapState('Block', ['creators']),
    creator() {
      return this.document.creator?.name || '';
    },
    tableOfContents() {
      return this.blockList.getTableOfContents();
    },
  },
  methods: {
    async searchResults(searchTerm) {
      let response = await searchResults(
        searchTerm,
        false,
        null,
        this.currentPage
      );
      this.resultsPagination = response.response;
    },
    onSelectChange(e, result) {
      if (e.target.checked) {
        this.selectedDocuments.push(result);
        const link = {
          destination: result.id,
          linkedDocument: null,
          text: '',
          source: this.block,
          inPlace: this.inPlace,
          linkType: 'internal',
          display: result.text,
          tab: '0',
        };

        this.$emit('addLink', link);
      } else {
        this.selectedDocuments.splice(
          this.selectedDocuments.indexOf(result),
          1
        );

        this.$emit('removeLink', result.id);
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    getText(block) {
      return stripHTML(textWithoutLinks(block.text));
    },
  },
};
</script>
