<template>
  <div ref="block" class="relative flex w-full pb-4">
    <div class="w-1/2 max-w-1/2">
      <div
        ref="blockWrapper"
        class="mr-16 block--wrapper"
        :style="{ 'margin-left': source.depth * 40 + 'px' }"
      >
        <div v-if="!isImageBlock" class="w-full">
          <table-block
            v-if="isTableBlock"
            :block="source"
            :text="text"
            :document="document"
            :block-list="blockList"
            @input="updateBlock"
          />
          <p
            v-else
            :id="`block-${source.id}`"
            ref="editingBlockText"
            v-line-clamp="lines"
            class="block-text"
            contenteditable
            @input="updateBlock"
            @click="onSelect"
            v-html="text"
          />
        </div>

        <div v-else>
          <img :src="source.imagePath" alt="Bild" />
        </div>
        <admin-document-edit-block-tool-bar :block="source" />
      </div>
    </div>
    <block-link-list
      v-if="blockLinks.length > 0"
      class="links-box"
      :links="blockLinks"
      :title="textWithoutHtml"
    />
  </div>
</template>

<script>
import EditorEventBus from '@/helpers/EditorEventBus';
import { replaceLinksToHTMLLinks } from '@/helpers/links';
import TableBlock from '@/components/admin/table/TableBlock';
import BlockLinkList from '@/components/admin/link/BlockLinkList.vue';
import AdminDocumentEditBlockToolBar from './AdminDocumentEditBlockToolBar.vue';

export default {
  name: 'AdminDocumentEditBlock',
  components: {
    TableBlock,
    BlockLinkList,
    AdminDocumentEditBlockToolBar,
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
    showBlockLinkModal: {
      type: Function,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    blockList: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      text: '',
      lines: 1,
    };
  },
  computed: {
    isImageBlock() {
      return this.source.htmlElementReference === 'image';
    },
    isTableBlock() {
      return this.source.htmlElementReference === 'table';
    },
    textWithoutHtml() {
      return this.text ? this.text.replace(/<[^>]*>?/gm, '') : '-';
    },
    blockLinks() {
      return this.source.links.filter((link) => !link.inPlace);
    },
  },
  watch: {
    'source.links'() {
      this.setText();
    },
  },
  mounted() {
    // Set the text at the beginning to prevent reactivity trigger
    this.setText();
    EditorEventBus.$on('updateBlockTable', this.updateBlockTable);
    window.addEventListener('click', this.handleWindowClick, { capture: true });
  },
  beforeDestroy() {
    EditorEventBus.$off('updateBlockTable', this.updateBlockTable);
    window.removeEventListener('click', this.handleWindowClick);
  },
  methods: {
    updateBlock(e) {
      EditorEventBus.$emit(
        'updateBlockText',
        this.source.id,
        e.target.innerHTML
      );
    },
    setText() {
      this.text = replaceLinksToHTMLLinks(this.source.text, this.source.links);
    },
    onDeSelect() {
      if (this.lines === 9999) this.lines = 1;
    },
    onSelect() {
      if (!this.$refs.editingBlockText) {
        return;
      }
      const parent = this.$refs.blockWrapper;

      // Check whether the text height is more than one line if so we need to expand the block.
      if (this.$refs.editingBlockText.scrollHeight > parent.clientHeight) {
        this.lines = 9999;
      }
    },
    updateBlockTable(blockId, text) {
      if (blockId === this.source.id) {
        this.text = replaceLinksToHTMLLinks(text, this.source.links);
        EditorEventBus.$emit('updateBlockText', this.source.id, this.text);
      }
    },
    showLinkModal() {
      this.showBlockLinkModal(this.source.id);
    },
    handleWindowClick() {
      let isOutsideClick = true;

      const possibleElements = document.querySelectorAll(
        '.popover, .modal, .dg-open, .context-menu'
      );
      possibleElements.forEach((element) => {
        if (element.contains(event.target)) {
          isOutsideClick = false;
        }
      });

      if (
        this.$refs.blockWrapper &&
        this.$refs.blockWrapper.contains(event.target)
      ) {
        isOutsideClick = false;
      }

      if (!isOutsideClick) {
        return;
      }
      this.lines = 1;
    },
  },
};
</script>

<style lang="postcss" scoped>
.block--wrapper {
  @apply flex items-center justify-between;
  @apply px-4 py-2;
  @apply border border-blue-700 rounded;
}

.block-text {
  word-break: break-word !important; /* Needs to be important to adjust line clamp package */
}

.links-box {
  @apply max-w-md rounded w-full border-0;
}
</style>
