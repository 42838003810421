<template>
  <div>
    <div v-if="isCopiedDocument || isNoPdfUploaded">
      <h2 class="mb-8 text-lg">{{ $t('editor.copiedDocument.title') }}</h2>
      <p class="mb-8 text-lg">{{ $t('editor.copiedDocument.lead') }}</p>

      <upload-file-zone @input="uploadFile" />
    </div>
    <div v-else>
      <iframe
        title="PDF Viewer"
        :src="`/pdfjs/web/viewer.html?file=${pdfSource}`"
        class="h-[72vh] w-full"
      />
    </div>
  </div>
</template>

<script>
import UploadFileZone from '@/components/admin/upload/UploadFileZone';
import EditorEventBus from '@/helpers/EditorEventBus';
import { uploadNewPdf } from '@/services/document';

export default {
  components: {
    UploadFileZone,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    tab: {
      type: String,
      default: '',
    },
  },
  computed: {
    rootBlock() {
      return this.document.block[0];
    },
    isCopiedDocument() {
      if (this.document.importType !== 'full' || !this.rootBlock) return false;
      return !!(
        this.document.state === 'draft' &&
        this.rootBlock.hasHistory &&
        !this.document.pdf
      );
    },
    isNoPdfUploaded() {
      return !this.document.pdf;
    },
    pdfSource() {
      return `${process.env.VUE_APP_MEDIA_URL}${this.document.pdf}#view=FitH`;
    },
    activeLang() {
      return this.$route.query.activeLang;
    },
  },
  methods: {
    async uploadFile(pdf) {
      try {
        await uploadNewPdf(this.document.id, {
          ...this.document,
          pdf: pdf,
          language: this.activeLang,
        });
        EditorEventBus.$emit('reloadDocument');
        this.$notify({
          type: 'success',
          text: this.$t('editor.copiedDocument.uploadSuccess').toString(),
        });
      } catch (e) {
        this.$notify({
          type: 'error',
          text:
            e.response?.data?.message ||
            this.$t('editor.copiedDocument.uploadError').toString(),
        });
      }
    },
  },
};
</script>
