<template>
  <component
    :is="element"
    :class="{
      'bg-blue-800 bg-opacity-[15%]': isSelected,
    }"
    class="p-0"
  >
    <div
      v-once
      class="w-full h-full p-2"
      contenteditable
      :data-row-index="rowIndex"
      :data-col-index="colIndex"
      @input="onInput"
      @focus="onCellFocus"
      v-html="text"
    />
  </component>
</template>

<script>
export default {
  props: {
    element: {
      type: String,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    colIndex: {
      type: Number,
      required: true,
    },
    selectedColumn: {
      type: Array,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    isSelected() {
      return (
        this.selectedColumn[0] === this.rowIndex &&
        this.selectedColumn[1] === this.colIndex
      );
    },
  },
  methods: {
    onCellFocus() {
      this.$emit('cellFocus', this.rowIndex, this.colIndex);
    },
    onInput(e) {
      this.$emit(
        'updateText',
        e.target.innerHTML,
        this.rowIndex,
        this.colIndex
      );
    },
  },
};
</script>
