<template>
  <div v-if="isContextMenuOpen" :style="position" class="fixed context-menu">
    <div class="flex bg-white rounded shadow-lg">
      <button
        class="context-button"
        :disabled="isCurrentSelectionALink"
        :title="$t('tooltips.contextLink')"
        @click="showLinkModal"
      >
        <svg-icon name="link" />
      </button>
      <button
        class="context-button"
        :disabled="!isCurrentSelectionALink"
        :title="$t('tooltips.contextUnlink')"
        @click="deleteLink"
      >
        <svg-icon name="unlink" />
      </button>
    </div>
  </div>
</template>

<script>
import contextMenuMixin from './contextMenuMixin';
import { hasCurrentSelectionGivenHtmlElement } from '@/helpers/selection';

export default {
  mixins: [contextMenuMixin],
  data() {
    return {
      isCurrentSelectionALink: false,
    };
  },
  methods: {
    showLinkModal() {
      this.$emit('showLinkModal');
    },
    deleteLink() {
      this.$emit('delete');
    },
    openContextMenuCallback() {
      this.isCurrentSelectionALink = hasCurrentSelectionGivenHtmlElement('a');
    },
  },
};
</script>
