<template>
  <div class="flex flex-col">
    <button
      class="rounded-tr switch-btn"
      :class="{ active: currentMode === 'pdf' }"
      @click="switchToPdf"
    >
      <svg-icon name="pdf" class="svg-icon--lg" />
    </button>
    <button
      class="rounded-br switch-btn"
      :class="{ active: currentMode === 'block' }"
      @click="switchToBlock"
    >
      <svg-icon name="view" class="svg-icon--lg" />
    </button>
  </div>
</template>

<script>
const PDF_MODE = 'pdf';
const BLOCK_MODE = 'block';

export default {
  props: {
    initial: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentMode: this.initial ? this.initial : PDF_MODE,
    };
  },
  methods: {
    switchToPdf() {
      this.currentMode = PDF_MODE;
      this.$emit('changeView', this.currentMode);
    },
    switchToBlock() {
      this.currentMode = BLOCK_MODE;
      this.$emit('changeView', this.currentMode);
    },
  },
};
</script>

<style lang="postcss" scoped>
.switch-btn {
  @apply w-9 h-9;
  @apply flex items-center justify-center;
  @apply bg-gray-50 text-blue-800;

  &.active {
    @apply bg-blue-800 text-white;
  }
}
</style>
