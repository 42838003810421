<template>
  <div class="w-full mb-10 border-t border-b border-gray-800 bg-gray-50">
    <button
      class="table--button"
      :disabled="isNoColumnSelected"
      :title="$t('tooltips.tablePicture')"
      @click="onClick('picture')"
    >
      <svg-icon name="picture" />
    </button>
    <button
      class="table--button"
      :disabled="linkDisabled"
      :title="$t('tooltips.tableLink')"
      @click="onClick('link')"
    >
      <svg-icon name="link" />
    </button>
    <button
      class="table--button"
      :disabled="unlinkDisabled"
      :title="$t('tooltips.tableUnlink')"
      @click="onClick('unlink')"
    >
      <svg-icon name="unlink" />
    </button>
    <button
      class="table--button"
      :disabled="hasHeader"
      :title="$t('tooltips.tableHeader')"
      @click="onClick('header')"
    >
      <svg-icon name="table-header" />
    </button>
    <button
      class="table--button"
      :disabled="isNoColumnSelected"
      :title="$t('tooltips.tableRowAbove')"
      @click="onClick('rowAbove')"
    >
      <svg-icon name="table-row-above" />
    </button>
    <button
      class="table--button"
      :disabled="isNoColumnSelected"
      :title="$t('tooltips.tableRowBelow')"
      @click="onClick('rowBelow')"
    >
      <svg-icon name="table-row-below" />
    </button>
    <button
      class="table--button"
      :disabled="isNoColumnSelected"
      :title="$t('tooltips.tableDeleteRow')"
      @click="onClick('removeRow')"
    >
      <svg-icon name="delete-row" />
    </button>
    <button
      class="table--button"
      :disabled="isNoColumnSelected"
      :title="$t('tooltips.tableColLeft')"
      @click="onClick('colLeft')"
    >
      <svg-icon name="table-column-left" />
    </button>
    <button
      class="table--button"
      :disabled="isNoColumnSelected"
      :title="$t('tooltips.tableColRight')"
      @click="onClick('colRight')"
    >
      <svg-icon name="table-column-right" />
    </button>
    <button
      class="table--button"
      :disabled="isNoColumnSelected"
      :title="$t('tooltips.tableRemoveCol')"
      @click="onClick('removeCol')"
    >
      <svg-icon name="delete-column" />
    </button>
  </div>
</template>

<script>
import { hasCurrentSelectionGivenHtmlElement } from '@/helpers/selection';

export default {
  props: {
    table: {
      type: Object,
      required: true,
    },
    selectedColumn: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isCurrentSelectionALink: false,
      noSelection: true,
    };
  },
  computed: {
    isNoColumnSelected() {
      return this.selectedColumn.length === 0;
    },
    hasHeader() {
      return this.table && this.table.header !== null;
    },
    linkDisabled() {
      return this.isCurrentSelectionALink || this.noSelection;
    },
    unlinkDisabled() {
      return !this.isCurrentSelectionALink || this.noSelection;
    },
  },
  mounted() {
    document.addEventListener('selectionchange', () => {
      // Check if it is a collapsed selection
      if (window.getSelection().isCollapsed) {
        this.noSelection = true;
        return;
      }
      this.noSelection = false;
      this.isCurrentSelectionALink = hasCurrentSelectionGivenHtmlElement('a');
    });
  },
  methods: {
    onClick(button) {
      this.$emit(button);
    },
  },
};
</script>

<style lang="postcss" scoped>
.table--button {
  @apply p-2;
  @apply text-blue-600;
  @apply disabled:text-gray-300 disabled:pointer-events-none;

  & svg {
    @apply w-5 h-5;
  }
}
</style>
