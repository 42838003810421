<template>
  <div>
    <div class="flex">
      <div v-for="language in languages" :key="`search-lang--${language}`">
        <button
          v-if="documents[language]"
          class="px-4 py-1 text-secondary-600"
          :class="{ 'is-active': activeLanguage === language }"
          @click="changeLanguageTab(language)"
        >
          {{ language.toUpperCase() }} ({{ documents[language].count }})
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import EditorEventBus from '@/helpers/EditorEventBus';

export default {
  name: 'SearchResultTabs',
  props: {
    documents: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    activeLanguage: {
      type: String,
      default: 'de',
    },
  },
  data() {
    return {
      languages: ['de', 'fr', 'it', 'en'],
    };
  },
  async mounted() {
    EditorEventBus.$on('reloadSearch', this.resetLanguage);
  },
  beforeDestroy() {
    EditorEventBus.$off('reloadSearch', this.resetLanguage);
  },
  methods: {
    async resetLanguage() {
      this.changeLanguageTab(this.$i18n.locale);
    },
    changeLanguageTab(lang) {
      this.$emit('changeLanguageTab', lang);
    },
  },
};
</script>

<style lang="postcss" scoped>
.is-active {
  @apply bg-white;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1), 0 -1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 3px 3px 0 0;
}
</style>
