<template>
  <div class="flex">
    <admin-pdf-view
      class="flex-1 mr-28"
      :document="document"
      tab="description"
    />
    <form class="flex-1 pb-7">
      <label-field
        :label="$t('editDescription.label')"
        class="w-full h-full"
        is-block
        :required="true"
        :label-class="[]"
      >
        <textarea v-model="description" class="w-full h-full border-blue-700" />
      </label-field>
    </form>
  </div>
</template>

<script>
import AdminPdfView from '@/components/admin/AdminPdfView';
import LabelField from '@/components/LabelField';
import EditorEventBus from '@/helpers/EditorEventBus';
import { updateDocumentDescription } from '@/services/document';

export default {
  components: {
    AdminPdfView,
    LabelField,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    activeLanguage: {
      type: String,
      required: true,
    },
  },
  computed: {
    description: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  mounted() {
    EditorEventBus.$on('saveDocumentDescription', this.saveDocumentDescription);
  },
  beforeDestroy() {
    EditorEventBus.$off(
      'saveDocumentDescription',
      this.saveDocumentDescription
    );
  },
  methods: {
    async saveDocumentDescription() {
      const payload = {
        language: this.activeLanguage,
        description: this.description,
      };
      try {
        await updateDocumentDescription(this.document.id, payload);
        this.$notify({
          type: 'success',
          text: this.$t('editDescription.success'),
        });
      } catch (e) {
        this.$notify({
          type: 'error',
          text: e?.response?.data?.message || this.$t('editDescription.error'),
        });
      }
    },
  },
};
</script>

<style lang="postcss" scoped></style>
