<template>
  <div class="w-full p-2 overflow-hidden border max-h-28">
    <p
      :id="`block-${block.id}`"
      class="word-break block-table"
      no-selection-links
      contenteditable
      @input="updateBlock"
      @focus="toggleTableEditor"
      v-html="text"
    ></p>
    <table-editor
      v-if="isTableEditorShown"
      :block="block"
      :text="text"
      :document="document"
      :block-list="blockList"
      @close="toggleTableEditor"
    />
  </div>
</template>

<script>
import TableEditor from './TableEditor.vue';

export default {
  components: {
    TableEditor,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    blockList: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isTableEditorShown: false,
    };
  },
  methods: {
    updateBlock(e) {
      this.$emit('input', e);
    },
    toggleTableEditor() {
      this.isTableEditorShown = !this.isTableEditorShown;
    },
  },
};
</script>

<style lang="postcss" scoped>
.word-break {
  word-break: break-word !important;
}
</style>
