import { replaceLinksToHTMLLinks } from './links';

let tableEntryId = 0;

const increaseTableEntryId = () => {
  tableEntryId++;
};

const parseHtmlToTableObject = (text, links) => {
  const tableObject = {
    header: null,
    body: {
      rows: [],
    },
  };

  const html = new DOMParser().parseFromString(text, 'text/html');
  const table = html.getElementsByTagName('table')[0];

  if (table.getElementsByTagName('thead').length > 0) {
    const thead = table.getElementsByTagName('thead')[0];
    const theadRow = thead.getElementsByTagName('tr')[0];
    const theadRowColumns = theadRow.getElementsByTagName('th');

    tableObject.header = { columns: [] };

    for (let i = 0; i < theadRowColumns.length; i++) {
      const theadRowColumn = theadRowColumns[i];
      tableObject.header.columns.push({
        id: tableEntryId,
        text: replaceLinksToHTMLLinks(theadRowColumn.innerHTML, links),
      });
      tableEntryId++;
    }
  }

  const rows = table
    .getElementsByTagName('tbody')[0]
    .getElementsByTagName('tr');
  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    const rowElement = rows[rowIndex];
    const row = { columns: [] };
    const columns = rowElement.getElementsByTagName('td');
    for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
      row.columns.push({
        id: tableEntryId,
        text: replaceLinksToHTMLLinks(columns[columnIndex].innerHTML, links),
      });
      tableEntryId++;
    }
    tableObject.body.rows.push(row);
  }

  return tableObject;
};

const parseTableObjectToHtml = (tableObject) => {
  let table = '<table>';

  if (tableObject.header !== null) {
    table += '<thead><tr>';

    const columns = tableObject.header.columns;

    for (let i = 0; i < columns.length; i++) {
      table += `<th>${columns[i].text}</th>`;
    }

    table += '</tr></thead>';
  }

  table += '<tbody>';

  const rows = tableObject.body.rows;
  for (let i = 0; i < rows.length; i++) {
    table += '<tr>';
    const rowColumns = rows[i].columns;
    for (let j = 0; j < rowColumns.length; j++) {
      table += `<td>${rowColumns[j].text}</td>`;
    }
    table += '</tr>';
  }

  table += '</tbody></table>';
  return table;
};

export {
  parseHtmlToTableObject,
  parseTableObjectToHtml,
  tableEntryId,
  increaseTableEntryId,
};
