<template>
  <div class="text-blue-600" tabindex="-1">
    <ol class="custom-dropdown--options">
      <li class="text-gray-400 !border-0 pointer-events-none">
        {{ $t('blockStructure.blockFormat.pleaseChoose') }}
      </li>
      <li
        v-for="elementReference in allowedElementReferences"
        :key="`format-${elementReference[0]}`"
        class="first:mt-6"
        :class="{
          'custom-dropdown--selected':
            block.htmlElementReference === elementReference[0],
        }"
        @click="setFormat(elementReference[0])"
      >
        {{ elementReference[1] }}
      </li>
    </ol>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import EditorEventBus from '@/helpers/EditorEventBus';

export default {
  name: 'BlockChangeFormat',
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('Block', ['elementReferences']),
    allowedElementReferences() {
      return this.elementReferences.filter(
        (el) => el[0] !== 'image' && el[0] !== 'table'
      );
    },
  },
  methods: {
    setFormat(format) {
      EditorEventBus.$emit('updateBlockFormat', this.block.id, format);
    },
  },
};
</script>

<style lang="postcss" scoped>
.custom-dropdown--options {
  @apply !max-h-[400px];
  @apply !bg-gray-50;
  @apply !rounded;
  @apply pb-4;
}
</style>
