<template>
  <div>
    <div v-if="document" class="flex">
      <div
        v-for="lang in document.documentLanguages"
        :key="`document-lang--${lang}`"
      >
        <button
          class="px-4 py-1 language-tab"
          :class="{ 'is-active': activeLanguage === lang }"
          @click="changeLanguageTab(lang)"
        >
          {{ lang.toUpperCase() }}
          <i @click.prevent.stop="removeLanguageVersion(lang)">
            <svg-icon
              v-if="showDeleteButton(lang)"
              class="inline ml-4 svg-icon--lg"
              name="bin"
            />
          </i>
        </button>
      </div>
      <button
        class="ml-2 language-tab"
        :class="{ hidden: isLanguageAddHidden }"
        @click="toggleUploadModal"
      >
        {{ $t('admin.languageTabs.addTab') }}
      </button>
    </div>
    <div v-else />
    <upload-document
      :is-open="isUploadModalOpen"
      :is-language-add="true"
      :document="document"
      @close="toggleUploadModal"
    />
  </div>
</template>

<script>
import UploadDocument from '@/components/admin/upload/UploadDocument';
import EditorEventBus from '@/helpers/EditorEventBus';
import { deleteLanguage } from '@/services/document';

export default {
  name: 'AdminLanguageTabs',
  components: {
    UploadDocument,
  },
  props: {
    document: {
      type: Object,
      default: null,
    },
    activeLanguage: {
      type: String,
      default: 'de',
    },
  },
  data() {
    return {
      isUploadModalOpen: false,
    };
  },
  computed: {
    isLanguageAddHidden() {
      if (!this.document.isLanguageAddable) {
        return true;
      }
      if (this.document) {
        return this.document.documentLanguages.length === 4;
      }
      return false;
    },
  },
  methods: {
    showDeleteButton(lang) {
      if (!this.document.isLanguageAddable) {
        return false;
      }
      if (this.document.documentLanguages.length === 1) {
        return false;
      }
      return lang === this.activeLanguage;
    },
    toggleUploadModal() {
      this.isUploadModalOpen = !this.isUploadModalOpen;
    },
    changeLanguageTab(lang) {
      EditorEventBus.$emit('changeLanguageTab', lang);
    },
    async removeLanguageVersion(lang) {
      const confirmMessage = {
        title: this.$t('admin.deleteLanguageVersion.title'),
        body: {
          note: this.$t('admin.deleteLanguageVersion.body'),
        },
      };
      const options = {
        okText: this.$t('admin.deleteLanguageVersion.yes'),
      };
      this.$dialog.confirm(confirmMessage, options).then(async () => {
        await deleteLanguage(this.document.id, lang);
        this.$emit('removeLanguageTab', lang);
      });
    },
  },
};
</script>

<style lang="postcss" scoped>
.language-tab {
  @apply text-blue-600;
}

.is-active {
  @apply bg-white;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1), 0 -1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 3px 3px 0 0;
}
</style>
